import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AccountTemplateModel, AccountTemplateRuleModel, ActiveViolationModel, BalanceSource, IActiveViolationModel, ITradingAccountModel, TradingAccountModel, TradingAccountStatus, TradingAccountType, TradingRuleModel, UserModel } from '../api/userApi';
import { useApi } from './ApiContext';
import { useAuth } from './AuthContext';
import { TradeFollower, TradingAccountUpdateModel, useCqg } from './CqgContext';
import { RtcUpdate } from '@models/rtcUpdate';
import { ErrorMessage } from '@components/errorMessage';
import Button, { ButtonType } from '@components/topstep/button';
import dayjs from 'dayjs';
import { TradingEnvironment } from 'src/data/tradingEnvironment';
import { env } from 'process';
import { Box, Link, Tooltip, Typography } from '@mui/material';
import Panel from '@components/topstep/panel';
import Heading from '@components/topstep/heading';
import { CenteredBox, StyledButton } from '@components/styledComponents';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import { setIn } from 'formik';
import { SessionData } from 'src/data/sessionData';
import { UpdateAction } from '@models/updateAction';
import { logException } from '@/helpers/exceptionHelper';
import styles from './styles.module.scss';
import { config } from '@/config';
import { useDeviceContext } from '@/contexts/DeviceContext';
import { toast } from 'react-toastify';
import Decimal from 'decimal.js';

interface AccountTemplateInfo {
  template: AccountTemplateModel;
  rules: AccountTemplateRuleModel[];
}
export interface IExtendedTradingAccountModel extends ITradingAccountModel {
  activeViolation: IActiveViolationModel | null;
}
export interface ITradingAccountContext {
  tradingEnvironment: TradingEnvironment;
  activeTradingAccount: IExtendedTradingAccountModel;
  setActiveTradingAccount: (account: IExtendedTradingAccountModel) => void;
  tradingAccounts: IExtendedTradingAccountModel[];
  refreshTradingAccounts: () => void;
  getAccountTemplateData: (templateID: number) => AccountTemplateInfo;
  hasLiveAccounts: boolean;
  hasSimAccounts: boolean;
  tradingRules: TradingRuleModel[];
  //No dependencies
  getBalanceSource: (account: ITradingAccountModel, source: BalanceSource) => number | null;
}

export const TradingAccountContext = React.createContext<ITradingAccountContext>({} as any);
export const useTradingAccount = () => React.useContext<ITradingAccountContext>(TradingAccountContext);

function TradingAccountContextProvider({ children }: any) {
  const { isMobile } = useDeviceContext();
  const { tradingAccountApi, violationsApi, accountTemplateRulesApi, accountTemplatesApi, tradeRulesApi } = useApi();
  const { loggedIn, userId, logOut, sessionId } = useAuth();

  const [tradingEnv, setTradingEnv] = useState<TradingEnvironment>(TradingEnvironment.None);

  const [accounts, setAccounts] = useState<IExtendedTradingAccountModel[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<IExtendedTradingAccountModel>(null);
  const [loading, setLoading] = useState(true);
  const [init, setInit] = useState(false);
  const { subscribeAccounts, unsubscribeAccounts, subscribeOnUserReconnect, unsubscribeOnUserReconnect, subscribeSessions, unsubscribeSessions, subscribeTradeFollows, unsubscribeTradeFollows, setTradingEnvironment } = useCqg();
  const [activeViolation, setActiveViolation] = useState<IActiveViolationModel | null>(null);
  const [accountTemplates, setAccountTemplates] = useState<AccountTemplateModel[]>([]);
  const [accountTemplateRules, setAccountTemplateRules] = useState<Map<number, AccountTemplateRuleModel[]>>(new Map());
  const [tradingRules, setTradingRules] = useState<TradingRuleModel[]>([]);
  const [initialAccountId, setInitialAccountId] = useState<number | null>(null);
  const templates = useRef<AccountTemplateModel[]>([]);
  const initialId = useMemo(() => {
    if (tradingEnv == TradingEnvironment.None) return;

    if (!initialAccountId) {
      const id = localStorage.getItem('activeAccount:' + tradingEnv) ? parseInt(localStorage.getItem('activeAccount:' + tradingEnv)) || null : null;
      setInitialAccountId(id);

      return id;
    } else {
      return initialAccountId;
    }
  }, [tradingEnv, initialAccountId]);

  useEffect(() => {
    if (!userId || !sessionId) {
      return;
    }

    const sessionError = () => (
      <div>
        Multiple sessions detected. Exchange regulations permit only one active device session at a time. You have been disconnected from this session. More info{' '}
        <Link href='https://help.projectx.com/getting-started/logging-in/concurrent-sessions-limit'>Here</Link>
      </div>
    );

    const callback = (update: RtcUpdate<SessionData>) => {
      if (update.action == UpdateAction.Deleted && sessionId == update.data.id) {
        toast.error(sessionError, {
          autoClose: false
        });
        logOut();
      }
    };
    subscribeSessions(userId, callback);
    return () => unsubscribeSessions(userId, callback);
  }, [userId, sessionId]);

  const updateAccountViolations = useCallback((accountId: number) => {
    violationsApi.getActiveViolation(accountId).then((data) => {
      setAccounts((existing) => {
        let updated = false;
        for (const updatingAccount of existing) {
          if (updatingAccount.accountId == accountId) {
            updatingAccount.activeViolation = data;
            updated = true;
          }
        }
        if (updated) return [...existing];
        return existing;
      });
    });
  }, []);

  useEffect(() => {
    tradeRulesApi
      .getTradingRules()
      .then((data) => {
        setTradingRules(data);
      })
      .catch((e) => {
        logException(e, 'Failed to get trading rules');
      });
  }, []);

  useEffect(() => {
    if (!userId) {
      return;
    }

    const callback = (update: RtcUpdate<TradingAccountUpdateModel>) => {
      setAccounts((existing) => {
        var data = update.data;
        const updatedAt = dayjs(data.updatedAt);

        let found = false;
        for (const acct of existing) {
          if (acct.accountId === data.id) {
            found = true;
            if (acct.updatedAt < updatedAt) {
              if (acct.status != data.status) {
                acct.status = data.status;

                if (acct.status !== TradingAccountStatus.Active && acct.status !== TradingAccountStatus.Paused) {
                  updateAccountViolations(data.id);
                } else {
                  acct.activeViolation = null;
                }
              }
              acct.combineDailyLoss = data.combineDailyLossLimit;
              acct.balance = data.balance;
              acct.realizedDayPnl = data.realizedPnL;
              acct.winRate = data.winPercentage;
              acct.dailyLoss = data.dailyLossLimit;
              acct.updatedAt = updatedAt;
              acct.totalTrades = data.totalTrades;
              acct.dailyTrades = data.dailyTrades;
              acct.personalDailyLossLimit = data.personalDailyLossLimit;
              acct.personalDailyLossLimitAction = data.personalDailyLossLimitAction;
              acct.personalDailyProfitTarget = data.personalDailyProfitTarget;
              acct.personalDailyProfitTargetAction = data.personalDailyProfitTargetAction;
              acct.templateId = data.accountTemplateId;
              acct.highestUnrealizedBalance = data.highestUnrealizedBalance;
              acct.highestBalance = data.highestBalance;
              acct.highestRealizedBalance = data.highestRealizedBalance;
              acct.nickname = data.nickname;
              acct.maxMargin = data.maxMargin;
              acct.maxPositions = data.maxPositionSize;
              acct.minimumMll = data.minimumMll;
            }

            break;
          }
        }

        if (!found) {
          refresh();
          return existing;
        }
        return [...existing.filter((y) => y.status != TradingAccountStatus.Closed)];
      });
    };

    const onTradeFollowerUpdate = (update: RtcUpdate<TradeFollower>) => {
      setAccounts((existing) => {
        var hasChanged = false;
        if (update.action == UpdateAction.Deleted) {
          var leader = existing.find((x) => x.accountId == update.data.leaderId);
          var follower = existing.find((x) => x.accountId == update.data.tradingAccountId);
          if (follower) {
            follower.isFollower = false;
            hasChanged = true;
          }
          var followers = existing.filter((x) => x.isFollower).length;

          if (leader && followers == 0) {
            hasChanged = true;
            leader.isLeader = false;
          }
        } else {
          for (const acct of existing) {
            if (acct.accountId === update.data.tradingAccountId) {
              acct.isFollower = true;
              hasChanged = true;
            }
            if (acct.accountId === update.data.leaderId) {
              acct.isLeader = true;
              hasChanged = true;
            }
          }
        }

        if (hasChanged) {
          return [...existing];
        } else {
          return existing;
        }
      });
    };

    subscribeAccounts(userId, callback);
    subscribeTradeFollows(onTradeFollowerUpdate);

    return () => {
      unsubscribeAccounts(userId, callback);
      unsubscribeTradeFollows(onTradeFollowerUpdate);
    };
  }, [userId]);

  const setActiveAccount = useCallback(
    (account: IExtendedTradingAccountModel) => {
      localStorage.setItem('activeAccount:' + tradingEnv, account.accountId.toString());
      setInitialAccountId(account.accountId);
      setSelectedAccount(account);
    },
    [tradingEnv]
  );

  const hasLiveAcc = useMemo(() => accounts.some((y) => y.type == TradingAccountType.Live), [accounts]);
  const hasSimAcc = useMemo(() => accounts.some((y) => y.type != TradingAccountType.Live), [accounts]);

  useEffect(() => {
    if (!hasLiveAcc && accounts.length > 0) {
      setTradingEnv(TradingEnvironment.Sim);
    }

    if (!hasSimAcc && hasLiveAcc) {
      setTradingEnv(TradingEnvironment.Live);
    }

    if (!init) {
      setInit(true);
    }
  }, [hasLiveAcc, accounts, init]);

  const filteredAccs = useMemo(
    () =>
      accounts.filter((x) => {
        if (tradingEnv == TradingEnvironment.Sim) {
          return x.type != TradingAccountType.Live;
        } else if (tradingEnv == TradingEnvironment.Live) {
          return x.type == TradingAccountType.Live;
        } else {
          return false;
        }
      }),
    [tradingEnv, accounts]
  );

  useEffect(() => {
    if (tradingEnv == TradingEnvironment.None) return;

    const savedAccount = initialId;
    if (savedAccount) {
      const account = filteredAccs.find((x) => x.accountId === savedAccount);
      if (account) {
        setSelectedAccount({ ...account });
        return;
      }
    }

    const practiceAccs = filteredAccs.filter((y) => y.type == TradingAccountType.Practice);
    if (practiceAccs.length > 0) {
      setInitialAccountId(practiceAccs[0].accountId);
      setSelectedAccount(practiceAccs[0]);
      return;
    }

    const simAccs = filteredAccs.filter((y) => y.type == TradingAccountType.Sim);
    if (simAccs.length > 0) {
      setInitialAccountId(simAccs[0].accountId);
      setSelectedAccount(simAccs[0]);
      return;
    }

    if (filteredAccs.length > 0) {
      setInitialAccountId(filteredAccs[0].accountId);
      setSelectedAccount(filteredAccs[0]);
      return;
    }

    setSelectedAccount(null);
  }, [filteredAccs, tradingEnv, initialAccountId]);

  const refresh = useCallback(() => {
    tradingAccountApi
      .get()
      .then((data) => {
        setAccounts(data.map((x) => ({ ...x, activeViolation: null })));
        for (const account of data) {
          var inViolation = account.status == TradingAccountStatus.Violation || account.status == TradingAccountStatus.TemporaryViolation ||account.status == TradingAccountStatus.GoalAchieved;
          if (inViolation) {
            updateAccountViolations(account.accountId);
          }
        }

        setLoading(false);
      })
      .catch((e) => {
        logException(e, 'Failed to get trading accounts');
      });
  }, []);

  useEffect(() => {
    if (!loggedIn) {
      return;
    }
    refresh();

    subscribeOnUserReconnect(refresh);

    return () => unsubscribeOnUserReconnect(refresh);
  }, [loggedIn]);

  useEffect(() => {
    var accTemplates = accounts.map((x) => x.templateId).filter((x) => x != null);
    var missingTemplates = accTemplates.filter((x) => !templates.current.some((y) => y.id == x));

    if (missingTemplates.length > 0) {
      accountTemplatesApi.getUserTemplates().then((data) => {
        templates.current = data;
        setAccountTemplates(data);
      });
    }
  }, [accounts]);

  const getBalanceSource = useCallback((account: ITradingAccountModel, source?: BalanceSource) => {
    if (!source) return null;

    switch (source) {
      case BalanceSource.StartingBalance: {
        return account.startingBalance;
      }
      case BalanceSource.HighestUnrealizedBalance: {
        return account.highestUnrealizedBalance;
      }
      case BalanceSource.StartOfDayBalance: {
        return account.startOfDayBalance;
      }
      case BalanceSource.CurrentBalance: {
        return account.balance + account.openPnl;
      }
      case BalanceSource.HighestBalance: {
        return account.highestBalance;
      }
      case BalanceSource.HighestRealizedBalance: {
        return account.highestRealizedBalance;
      }
      default:
        return null;
    }
  }, []);

  useEffect(() => {
    accountTemplates.forEach((template) => {
      const rules = accountTemplateRules.get(template.id);
      if (!rules) {
        accountTemplateRulesApi.getAllAttachedRules(template.id).then((data) => {
          setAccountTemplateRules((existing) => {
            existing.set(template.id, data);
            return new Map(existing);
          });
        });
      }
    });
  }, [accountTemplates]);

  const getAccountTemplateData = useCallback(
    (templateID: number) => {
      const template = accountTemplates.find((x) => x.id == templateID);
      if (!template) return null;

      const rules = accountTemplateRules.get(templateID);
      return { template, rules };
    },
    [accountTemplates, accountTemplateRules]
  );

  useEffect(() => {
    setTradingEnvironment(tradingEnv);
  }, [tradingEnv]);

  const values = useMemo(() => {
    const res: ITradingAccountContext = {
      refreshTradingAccounts: refresh,
      activeTradingAccount: selectedAccount,
      setActiveTradingAccount: setActiveAccount,
      tradingAccounts: filteredAccs,
      tradingEnvironment: tradingEnv,
      hasLiveAccounts: hasLiveAcc,
      hasSimAccounts: hasSimAcc,
      getAccountTemplateData: getAccountTemplateData,
      tradingRules: tradingRules,
      getBalanceSource
    };

    return res;
  }, [tradingRules, selectedAccount, accounts, tradingEnv, filteredAccs, hasLiveAcc, hasSimAcc, activeViolation, accountTemplates, accountTemplateRules]);

  return (
    <TradingAccountContext.Provider value={values}>
      {!loading && accounts.length == 0 && (
        <>
          <ErrorMessage>
            You have no active accounts. <br />
            <StyledButton onClick={logOut}>Log Out</StyledButton>
          </ErrorMessage>
        </>
      )}
      {!loading && init && tradingEnv == TradingEnvironment.None && hasSimAcc && hasLiveAcc && (
        <>
          <CenteredBox sx={{ marginTop: isMobile ? '-13%' : '' }}>
            <Heading style={{ textAlign: 'center', fontSize: isMobile ? '2em' : '4em' }}>Select a Trading Environment</Heading>
            <Box style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-around' }}>
              <div style={{ width: '30em' }} className={styles.box}>
                <Heading style={{ textAlign: 'center', paddingBottom: '0.5em' }}>Simulation</Heading>
                <Typography style={{ textAlign: 'center', paddingBottom: '1em' }}>Login to your {config.simAccountName}</Typography>
                <Box display='flex' flexDirection={'column'} alignItems={'center'} width='100%'>
                  <MoneyOffIcon style={{ fontSize: '6em', color: 'green' }}></MoneyOffIcon>
                  <StyledButton disabled={!hasSimAcc} sx={{ width: '15em', marginTop: '2em', marginBottom: '1em' }} color='success' onClick={() => setTradingEnv(TradingEnvironment.Sim)}>
                    Launch Sim
                  </StyledButton>
                  {!hasSimAcc && <Typography style={{ textAlign: 'center', paddingBottom: '1em', color: 'grey' }}>Note: You do not currently have any accounts on the simulated platform.</Typography>}
                </Box>
              </div>
              <div style={{ width: '30em' }} className={styles.box}>
                <Heading style={{ textAlign: 'center', paddingBottom: '0.5em' }}>Live</Heading>
                <Typography style={{ textAlign: 'center', paddingBottom: '1em' }}>Login to your Live Funded Account</Typography>
                <Box display='flex' flexDirection={'column'} alignItems={'center'} width='100%'>
                  <CurrencyExchangeIcon style={{ fontSize: '6em', color: 'red' }}></CurrencyExchangeIcon>
                  <StyledButton onClick={() => setTradingEnv(TradingEnvironment.Live)} sx={{ width: '15em', marginTop: '2em', marginBottom: '1em' }} color='error'>
                    Launch Live
                  </StyledButton>
                </Box>
              </div>
            </Box>
          </CenteredBox>
        </>
      )}
      {selectedAccount && children}
    </TradingAccountContext.Provider>
  );
}

export default TradingAccountContextProvider;
