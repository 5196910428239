import React, { PropsWithChildren, useEffect, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Loading from '../components/Loading';
import TradingAccountContextProvider from '../contexts/TradingAccountContext';
import CqgProvider from '../contexts/CqgContext';
import LayoutsContextProvider from '../contexts/LayoutsContext';
import SymbolContextProvider from '../contexts/SymbolContext';
import OrderContextProvider from '../contexts/OrdersContext';
import TiltContextProvider from '../contexts/TiltContext';
import { Box, styled } from '@mui/material';
import { useApi } from '../contexts/ApiContext';
import MarketStatusContextProvider from '@contexts/MarketStatusContext';
import SettingsContextProvider from '@contexts/SettingsContext';
import MobileTopbar from './MobileTopbar';
import { Page } from './LayoutComponents';
import MobileFooter from './MobileFooter';
import LinkedContextProvider from '@/contexts/LinkedContext';
import ModalContextProvider from '@/contexts/ModalContext';
import { NavRoutes } from '@/data/NavRoutes';
import StrapiAlert from '@/components/topstep/strapiAlert';

const MobileContent = styled(Box)(({ theme }) => ({
  background: '#1c1e23',
  flexDirection: 'column',
  flex: 1,
  minHeight: 0
}));

const MobileProviders: React.FC<PropsWithChildren> = ({ children }): JSX.Element => {
  const { loggedIn } = useAuth();
  if (loggedIn) {
    return (
      <SettingsContextProvider>
        <CqgProvider>
          <TradingAccountContextProvider>
            <LayoutsContextProvider>
              <SymbolContextProvider>
                <LinkedContextProvider>
                  <OrderContextProvider>
                    <MarketStatusContextProvider>
                      <ModalContextProvider>
                        <TiltContextProvider>{children}</TiltContextProvider>
                      </ModalContextProvider>
                    </MarketStatusContextProvider>
                  </OrderContextProvider>
                </LinkedContextProvider>
              </SymbolContextProvider>
            </LayoutsContextProvider>
          </TradingAccountContextProvider>
        </CqgProvider>
      </SettingsContextProvider>
    );
  }
  return <React.Fragment>{children}</React.Fragment>;
};

const log = (msg: string) => window['ReactNativeWebView'] && window['ReactNativeWebView'].postMessage(msg);

const MobileLayout: React.FC = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const { loggedIn } = useAuth();
  const { setToken } = useApi();
  const [token] = useState(searchParams.get('token'));
  const nav = useNavigate();

  log('Starting Mobile...');

  useEffect(() => {
    if (!loggedIn && token) {
      setToken(token);
      log('Set Token: ' + token);
    }
  }, [loggedIn, token]);

  useEffect(() => {
    if (!loggedIn) {
      nav(NavRoutes.Login);
    }
  }, [loggedIn]);

  if (!loggedIn) {
    return <Loading />;
  }

  return (
    <MobileProviders>
      <Page display='flex' flexDirection='column' minHeight='100dvh' maxHeight='100dvh'>
        {/* Top bar and hamburger */}
        <Box display='flex' flexDirection='column' alignContent="space-between" flex={1} minHeight='100dvh' maxHeight='100dvh' maxWidth='100vw'>
          <StrapiAlert />
          <MobileTopbar />
          <MobileContent>{<Outlet />}</MobileContent>
          <MobileFooter />
        </Box>
      </Page>
    </MobileProviders>
  );
};

export default MobileLayout;
