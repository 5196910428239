import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useApi } from '../../../../contexts/ApiContext';
import { ITradeLogData, TradeLogData, TradeLogType } from '../../../../api/dataApi';
import { useSymbol } from '../../../../contexts/SymbolContext';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Box, TextField } from '@mui/material';
import { StyledTableHeaderCell } from '@components/styledComponents';
import { ContractPriceSpan, NumberSpan, RedGreenPriceSpan } from '@components/numbers';
import { useSettings } from '@contexts/SettingsContext';
import ContractSelector from '@/components/topstep/contractSelector';
import { SymbolMetadata } from '@/api/userApi';
import { useCqg } from '@/contexts/CqgContext';
import { LinkedColor } from '@/contexts/LinkedContext';
import { TsModal } from '@components/modal';
import { Settings } from '@mui/icons-material';
import SalesSettings from './salesSettings';
import { useDeviceContext } from '@/contexts/DeviceContext';
import HelpMessage from '@/components/helpMessage';
import Loading from '@/components/Loading';


interface TimeAndSalesProps {
  changed: () => void;
  tabData: {
    symbol: string;
    linkedColor: LinkedColor;
    volumeFilter?: number;
    callbacks: {
      onLinkedColorChange: (color: LinkedColor) => void;
    };
  };
}
const keyFormat = 'HH:mm:ss.SSS';
const Sales: React.FC<TimeAndSalesProps> = (props) => {
  const { symbols, getContractById, getDefaultContract } = useSymbol();
  const { isMobile } = useDeviceContext();
  const [contract, setContract] = useState<SymbolMetadata | null>(getContractById(props.tabData?.symbol) || getDefaultContract());
  const [linkedColor, setLinkedColor] = useState<LinkedColor>(props.tabData.linkedColor);
  const [volumeFilter, setVolumeFilter] = useState<number>(props.tabData.volumeFilter || 1);
  const [salesSettingsOpen, setSalesSettingsOpen] = useState<boolean>(false);
  const { customSettings } = useSettings();
  const { tradeLogApi } = useApi();
  const { subscribeTradeLog, unsubscribeTradeLog, unsubscribeOnChartReconnect, subscribeOnChartReconnect } = useCqg();
  const trades = useRef<ITradeLogData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [filteredTrades, setFilteredTrades] = useState<ITradeLogData[]>([]);
  const sizeFilterRef = useRef<number>(volumeFilter);

  const renderFontSize = useMemo(() => {
    switch (customSettings.salesFontSize) {
      case 0:
        return 12;
      case 1:
        return 14;
      case 2:
        return 16;
      default:
        return 12;
    }
  }, [customSettings.salesFontSize]);

  const onVolumeFilterChanged = useCallback(
    (value: string) => {
      const parsedValue = parseInt(value);
      if (isNaN(parsedValue)) return;
      if (props.tabData.volumeFilter != parsedValue) {
        props.tabData.volumeFilter = parsedValue;
        props.changed();
      }

      setVolumeFilter(parsedValue);
    },
    [props.tabData]
  );

  useEffect(() => {
    setLinkedColor(props.tabData.linkedColor);
  }, [props.tabData.linkedColor]);

  useEffect(() => {
    if (!props.tabData.callbacks) props.tabData.callbacks = { onLinkedColorChange: setLinkedColor };
    else props.tabData.callbacks.onLinkedColorChange = setLinkedColor;
  }, [props.tabData.callbacks]);

  useEffect(() => {
    if (props.tabData.symbol != contract?.symbol) {
      props.tabData.symbol = contract?.symbol;
      props.changed();
    }
  }, [contract]);

  useEffect(() => {
    if (!contract) return;
    setLoading(true);
    trades.current = [];
    setFilteredTrades([]);
    const fetchData = () => {
      tradeLogApi.get(contract.symbol)
        .then((data) => {
          trades.current = data;
          setFilteredTrades(data.filter((x) => x.volume >= sizeFilterRef.current));
          setLoading(false);
        })
        .catch(() => setLoading(false));
    };
  
    subscribeOnChartReconnect(fetchData);
    fetchData();
  
    const id = subscribeTradeLog(contract.symbol, (data: TradeLogData[]) => {
      trades.current.splice(0, 0, ...data);
      if (trades.current.length > 5000) {
        trades.current.splice(4000, trades.current.length - 4000);
      }
      setFilteredTrades(trades.current.filter((x) => x.volume >= sizeFilterRef.current).slice(0, 50)
                        .sort((a, b) => b.timestamp.diff(a.timestamp)));
    });

    return () => {
      unsubscribeTradeLog(contract.symbol, id);
    };
  }, [contract]);

  useEffect(() => {
    sizeFilterRef.current = volumeFilter;
    setFilteredTrades(trades.current.filter((x) => x.volume >= volumeFilter));
  }, [volumeFilter]);

  const toggleSalesSettings = useCallback(() => {
    setSalesSettingsOpen((prev) => !prev);
  }, []);

  const tradesTable = useCallback(
    (size: Size) => {
      return (
        <TableContainer sx={{ height: size.height, width: size.width }}>
          <Table stickyHeader aria-label='sticky table' size='small'>
            <TableHead>
              <TableRow>
                <StyledTableHeaderCell>Trade Time</StyledTableHeaderCell>
                <StyledTableHeaderCell>
                  Price
                  <HelpMessage message="Red indicates orders are taken at or below bid price (bearish sentiment). Green indicates orders are taken at or above ask price (bullish sentiment)." />
                </StyledTableHeaderCell>
                <StyledTableHeaderCell>Size</StyledTableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTrades.map((x, i) => (
                <TableRow key={i}>
                  <TableCell style={{ padding: '0px 5px', color: '#8e8e8e' }}>
                    {' '}
                    <span style={{ fontSize: renderFontSize }}>{x.timestamp.format(keyFormat)}</span>
                  </TableCell>
                  <TableCell style={{ padding: '0px 5px' }}>
                    <span style={{ fontSize: renderFontSize }}>
                      <ContractPriceSpan contract={contract} showGreen={x.type == TradeLogType.Buy} showRed={x.type == TradeLogType.Sell}>
                        {x.price}
                      </ContractPriceSpan>
                    </span>
                  </TableCell>
                  <TableCell style={{ padding: '0px 5px' }}>
                    <span style={{ fontSize: renderFontSize }}>
                      <NumberSpan>{x.volume}</NumberSpan>
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    },
    [filteredTrades, renderFontSize, contract]
  );

  return useMemo(
    () => (
      <Box style={{ height: '100%', width: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column', maxHeight: isMobile ? 'calc(100% - 40px)' : '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ flex: 1 }}>
            <ContractSelector contract={contract} setContract={setContract} linkedColor={linkedColor} />
          </Box>
          <Box sx={{ width: '8em' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                variant='outlined'
                type='number'
                size='small'
                label='Size Filter'
                sx={{ minWidth: '1em' }}
                value={volumeFilter || '0'}
                InputProps={{
                  inputProps: {
                    min: 1,
                    step: 1
                  }
                }}
                style={{ margin: '1em 0' }}
                onChange={(t) => onVolumeFilterChanged(t.target.value)}
              />
              <div style={{ cursor: 'pointer', marginLeft: '.5em', marginRight: '.5em' }} onClick={toggleSalesSettings}>
                <Settings />
              </div>
            </div>
          </Box>
        </Box>
        <div style={{ height: '100%'}}>{loading ? <Loading></Loading>: <AutoSizer>{ tradesTable}</AutoSizer>}</div>
        <TsModal open={salesSettingsOpen} onClose={() => setSalesSettingsOpen(false)}>
          <SalesSettings onSaved={() => setSalesSettingsOpen(false)} onCancelled={() => setSalesSettingsOpen(false)}></SalesSettings>
        </TsModal>
      </Box>
    ),
    [tradesTable, contract, volumeFilter, linkedColor, salesSettingsOpen]
  );
};

export default React.memo(Sales);
