import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Loading from '../components/Loading';
import { useApi } from './ApiContext';
import { SymbolMetadata } from '../api/userApi';

export interface ISymbolContext {
  contract: SymbolMetadata;
  symbolId: number;
  symbolCqgName: string;
  symbols: SymbolMetadata[];
  symbolsById: Map<string, SymbolMetadata>;
  setContract: (symbol: SymbolMetadata) => void;
  setContractByFriendlyName: (symbol: string) => void;
  getContractByFriendlyName: (name: string) => SymbolMetadata | undefined;
  getContractById: (id: string) => SymbolMetadata | undefined;
  getDefaultContract: () => SymbolMetadata;
}

export const SymbolContext = React.createContext<ISymbolContext>({} as any);
export const useSymbol = () => React.useContext<ISymbolContext>(SymbolContext);

function SymbolContextProvider({ children }: any) {
  const [contract, setContract] = useState<SymbolMetadata>();
  const [symbols, setSymbols] = useState<SymbolMetadata[]>([]);
  const { metadataApi } = useApi();

  const symbolsRef = useRef<SymbolMetadata[]>([]);
  const symbolsByIdRef = useRef<Map<string, SymbolMetadata>>(new Map());
  const symbolsByFriendlyNameRef = useRef<Map<string, SymbolMetadata>>(new Map());

  const updateContract = useCallback((contract: SymbolMetadata) => {
    if (contract) {
      localStorage.setItem('symbol', contract.friendlyName);
      setContract(contract);
    }
  }, []);

  const getDefaultContract = useCallback(() => {
    return symbolsRef.current.find((x) => x.friendlyName === '/ES') || symbolsRef.current[0];
  }, []);

  const updateContractByFriendlyName = useCallback((symbolName: string) => {
    const symbolInfo = symbolsRef.current.find((x) => x.friendlyName === symbolName);
    updateContract(symbolInfo);
  }, []);

  useEffect(() => {
    let isMounted = true;
    metadataApi.get().then((res) => {
      if (isMounted) {
        const lastSymbol = localStorage.getItem('symbol');
        setSymbols(res);
        symbolsRef.current = res; //to prevent update requirements for updateSymbol
        var symbol = res.find((x) => x.friendlyName === (lastSymbol || '/ES'));
        if (!symbol) symbol = res.find((x) => x.friendlyName === '/ES');
        setContract(symbol);
        
        symbolsByIdRef.current.clear();
        symbolsByFriendlyNameRef.current.clear();

        res.forEach((x) => {
          symbolsByIdRef.current.set(x.symbol, x);
          symbolsByFriendlyNameRef.current.set(x.friendlyName, x);
        });
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  const symbolsById = useMemo(() => {
    const map = new Map<string, SymbolMetadata>();
    symbols.forEach((x) => map.set(x.symbol, x));
    return map;
  }, [symbols]);

  const symbolsByFriendlyName = useMemo(() => {
    const map = new Map<string, SymbolMetadata>();
    symbols.forEach((x) => map.set(x.friendlyName, x));
    return map;
  }, [symbols]);
  
  const getContractById = useCallback((id: string) => {
    return symbolsById.get(id);
  }, [symbolsById]);

  const getContractByFriendlyName = useCallback((name: string) => {
    return symbolsByFriendlyName.get(name);
  }, [symbolsByFriendlyName]);

  const values = useMemo(() => {
    return {
      symbolId: 0,
      contract,
      symbolCqgName: contract?.symbol,
      symbols,
      setContract: updateContract,
      setContractByFriendlyName: updateContractByFriendlyName,
      symbolsById,
      getContractByFriendlyName,
      getContractById,
      getDefaultContract,
    };
  }, [contract, symbols, symbolsById]);

  return <SymbolContext.Provider value={values}>{symbols.length > 0 ? children : <Loading />}</SymbolContext.Provider>;
}

export default SymbolContextProvider;
