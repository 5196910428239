import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSymbol } from '../../contexts/SymbolContext';
import { Autocomplete, AutocompleteRenderInputParams, Box, InputAdornment, MenuItem, TextField, Tooltip, createFilterOptions, styled } from '@mui/material';
import { useMarketStatus } from '@contexts/MarketStatusContext';
import { ExchangeStatus, MarketStatus, SymbolMetadata } from '@/api/userApi';
import { LinkedColor, useLinked } from '@/contexts/LinkedContext';
import { Circle, Square, Star } from '@mui/icons-material';
import { useSettings } from '@/contexts/SettingsContext';
import { faV } from '@fortawesome/pro-regular-svg-icons';
const getMarketStatus = (marketStatus: MarketStatus) => {
  if (!marketStatus) return 'Unknown';
  if (marketStatus.simStatus == ExchangeStatus.Closed) return 'Closed';

  switch (marketStatus.status) {
    case ExchangeStatus.Open:
      return 'Open';
    case ExchangeStatus.Closed:
      return 'Closed';
    case ExchangeStatus.PreOpen:
      return 'Pre-Open';
    case ExchangeStatus.Halted:
      return 'Paused';
    default:
      return 'Unknown';
  }
};
const getMarketColor = (marketStatus: MarketStatus) => {
  if (!marketStatus) return 'grey';
  if (marketStatus.simStatus == ExchangeStatus.Closed) return 'red';

  switch (marketStatus.status) {
    case ExchangeStatus.Open:
      return 'green';
    case ExchangeStatus.Closed:
      return 'red';
    case ExchangeStatus.PreOpen:
      return '#3cc8ff';
    case ExchangeStatus.Halted:
      return '#ff912e';
    default:
      return 'grey';
  }
};

interface ContractOption {
  label: string;
  desc: string;
  value: string;
  contract: SymbolMetadata;
  marketStatus: MarketStatus;
}

interface ContractSelectorProps {
  contract: SymbolMetadata;
  setContract: (contract: SymbolMetadata) => void;
  linkedColor: LinkedColor;
  style?: React.CSSProperties;
  size?: 'small' | 'medium' | undefined;
}

const ContractSelector: React.FC<ContractSelectorProps> = ({ style, contract, setContract, size, linkedColor }): JSX.Element => {
  const { symbols } = useSymbol();
  const { customSettings, saveCustomSettings } = useSettings();
  const { isOpen, getStatus } = useMarketStatus();
  const { links, changeLinkSymbol } = useLinked();
  const [filterText, setFilterText] = useState('');
  const renderContractMap = useRef<Map<string, boolean>>(new Map());

  const favorites = useMemo(() => {
    const favs = customSettings.favoriteContracts || [];
    const map = new Map(favs.map((x) => [x, true]));
    return map;
  }, [customSettings.favoriteContracts, customSettings.hideNonFavorites]);

  useEffect(() => {
    if (!filterText && customSettings.hideNonFavorites) {
      renderContractMap.current = favorites;
    } else {
      renderContractMap.current = new Map();
    }
  }, [filterText, customSettings.hideNonFavorites, favorites]);

  const addFavorite = useCallback(
    (contract: SymbolMetadata) => {
      if (favorites.has(contract.symbol)) return;

      customSettings.favoriteContracts = [...(customSettings.favoriteContracts || []), contract.symbol];

      saveCustomSettings(customSettings);
    },
    [customSettings, favorites, saveCustomSettings]
  );

  const removeFavorite = useCallback(
    (contract: SymbolMetadata) => {
      if (!favorites.has(contract.symbol)) return;

      customSettings.favoriteContracts = customSettings.favoriteContracts?.filter((x) => x !== contract.symbol);

      saveCustomSettings(customSettings);
    },
    [customSettings, favorites, saveCustomSettings]
  );

  const toggleFavorite = useCallback(
    (e: React.MouseEvent, contract: SymbolMetadata) => {
      if (favorites.has(contract.symbol)) removeFavorite(contract);
      else addFavorite(contract);

      e.preventDefault();
      e.stopPropagation();
    },
    [favorites, addFavorite, removeFavorite]
  );

  useEffect(() => {
    if (linkedColor) {
      setContract(links[linkedColor]?.symbol);
    }
  }, [links[linkedColor], linkedColor]);

  const autoCompleteBox = useMemo(() => {
    let options = [...symbols]
      .filter(symbol => !symbol.disabled) 
      .sort((a, b) => {
        if (favorites.has(a.symbol) && !favorites.has(b.symbol)) return -1;
        if (!favorites.has(a.symbol) && favorites.has(b.symbol)) return 1;

        return a.friendlyName.localeCompare(b.friendlyName);
      })
      .map((y) => {
        return {
          label: `${y.friendlyName.substring(1)}${y.maturityMonthYear}`,
          desc: y.fullName,
          marketStatus: getStatus(y.symbol),
          value: y.friendlyName,
          contract: y
        } as ContractOption;
      });

    const selected = options.find((x) => x.value === contract.friendlyName);
    const filter = createFilterOptions<ContractOption>({
      stringify: (option) => `${option.contract.friendlyName.substring(1)}${option.contract.maturityMonthYear} ${option.contract.fullName}`
    });

    return (
      <Box sx={{ margin: '1em 0.5em', display: 'flex', justifyContent: 'center', alignItems: 'center', justifyItems: 'center', alignContent: 'center', ...style }}>
        <Autocomplete
          sx={{ flex: 2 }}
          value={selected}
          options={options}
          size={size || 'small'}
          disableClearable
          filterOptions={(options, params) => {
            if (customSettings.hideNonFavorites && favorites.size > 0) {
              if(params.inputValue == selected?.value || params.inputValue == '') {
                options = [...options].filter(y => favorites.has(y.contract.symbol));
              }
            }
            return filter(options, params);
          }}
          renderOption={(props, option) => {
            return (
              <Box component='li' sx={{ '& > *': { mr: 1, ml: 0, flexShrink: 0 } }} {...props}>
                <Tooltip title={`Market State: ${getMarketStatus(option.marketStatus)}`} placement='top'>
                  <Square sx={{ color: getMarketColor(option.marketStatus), fontSize: '0.65em', marginRight: 0.5 }} />
                </Tooltip>
                <Tooltip title='Favorite'>
                  <Star sx={{ color: favorites.has(option.contract.symbol) ? '#ffd700' : 'grey', pointer: 'cursor' }} onClick={(e) => toggleFavorite(e, option.contract)}></Star>
                </Tooltip>
                <span>{option.label}</span>
                <span style={{ fontSize: '.8em' }}>{option.desc}</span>
              </Box>
            );
          }}
          onChange={(e, value) => {
            if (linkedColor) changeLinkSymbol(linkedColor, value?.contract);
            else setContract(value?.contract);
          }}
          renderInput={(params: AutocompleteRenderInputParams) => {
            const status = getStatus(contract.symbol);
            const color = getMarketColor(status);
            params.InputProps.startAdornment = (
              <InputAdornment position='end' sx={{ marginLeft: '0.1em' }}>
                <Tooltip title={`Market State: ${getMarketStatus(status)}`} placement='top'>
                  <Square sx={{ color: color, fontSize: '0.65em', margin: 0 }} />
                </Tooltip>
              </InputAdornment>
            );
            return <TextField label='Contract' {...params} />;
          }}
        />
      </Box>
    );
  }, [symbols, contract, setContract, isOpen, linkedColor, getStatus, favorites, toggleFavorite, customSettings.hideNonFavorites]);

  return <Box>{autoCompleteBox}</Box>;
};

export default React.memo(ContractSelector);
