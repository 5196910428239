import React, { lazy, Suspense } from 'react';
import './login-styles.scss';
import { Box } from '@mui/material';
import { TradingPlatforms, config } from '@/config';

interface Props {
  children?: React.ReactNode;
}
const UserPage: React.FC<Props> = ({ children }): JSX.Element => {

  // height of the login image
  let logoHeight; 
  switch (config.platform) {
    case TradingPlatforms.AlphaFutures:
      logoHeight = 200;
      break;
    case TradingPlatforms.FuturesDesk:
      logoHeight = 130;
      break;
    default:
      logoHeight = 80;
      break;
  }


  switch (config.platform) {
    // environments with a background image
    case TradingPlatforms.AlphaFutures:
    case TradingPlatforms.TickTickTrader:
      return (
        <Box
          style={{
            marginRight: 0,
            marginLeft: 0,
            height: '100vh',
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(${config.platformLoginBG})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',  
            backgroundSize: 'cover'
          }}
        >
          <Box
            style={{
              padding: 40,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '40em'
            }}
          >
            <Suspense fallback={<div></div>}>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                <img src={config.platformLoginLogo} style={{ height: logoHeight }} />
              </div>
            </Suspense>
            {children}
          </Box>
        </Box>
      );
    default:
      return (
        <Box
          style={{
            marginRight: 0,
            marginLeft: 0,
            height: '100vh',
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Box
            style={{
              padding: 40,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '40em'
            }}
          >
            <Suspense fallback={<div></div>}>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                <img src={config.platformLoginLogo} style={{ height: logoHeight }} />
              </div>
            </Suspense>
            {children}
          </Box>
        </Box>
      );
  }
};

export default UserPage;
