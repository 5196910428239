import { useApi } from '@contexts/ApiContext';
import { Box, Checkbox, FormControlLabel, FormGroup, FormLabel, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSettings } from '@contexts/SettingsContext';
import Button, { ButtonType } from '@components/topstep/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import HelpMessage from '@components/helpMessage';
import { useTradingAccount } from '@contexts/TradingAccountContext';
import { IUserSettingsModel, JsonDocument, PersonalDailyAction, PersonalDailyLimitsRequest, RootElement, TradingAccountType } from '@api/userApi';
import { networkSpeed, chartPlotSide, topNavTextSize } from 'src/data/enumTypeMaps';
import { logException } from '@/helpers/exceptionHelper';
import { toast } from 'react-toastify';
import { StyledButton } from '@/components/styledComponents';
import { useDeviceContext } from '@/contexts/DeviceContext';
import config from '@/config';


interface RiskSettingsProps {
  onSaved?: () => void;
  onCancelled?: () => void;
  showButtons?: boolean;
}

const RiskSettings: React.FC<RiskSettingsProps> = ({ onSaved, onCancelled, showButtons }) => {
  const settings = useSettings();
  const { tradingAccountApi } = useApi();
  const { activeTradingAccount } = useTradingAccount();
  const { isMobile } = useDeviceContext();
  const [risk, setRisk] = useState<number | null>(0);
  const [toMake, setToMake] = useState<number | null>(0);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [autoApply, setAutoApply] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [personalDailyLossLimit, setPersonalDailyLossLimit] = useState<number | null>(0);
  const [personalDailyLossLimitAction, setPersonalDailyLossLimitAction] = useState<PersonalDailyAction>(PersonalDailyAction.None);
  const [personalDailyProfitTarget, setPersonalDailyProfitTarget] = useState<number | null>(0);
  const [personalDailyProfitTargetAction, setPersonalDailyProfitTargetAction] = useState<PersonalDailyAction>(PersonalDailyAction.None);

  const hasDll = useMemo(() => {
    return activeTradingAccount.type == TradingAccountType.Express || activeTradingAccount.type == TradingAccountType.Live;
  }, [activeTradingAccount.type]);

  const resetDailyLimits = useCallback(() => {
    setPersonalDailyLossLimit(activeTradingAccount.personalDailyLossLimit);
    setPersonalDailyLossLimitAction(activeTradingAccount.personalDailyLossLimitAction);
    setPersonalDailyProfitTarget(activeTradingAccount.personalDailyProfitTarget);
    setPersonalDailyProfitTargetAction(activeTradingAccount.personalDailyProfitTargetAction);
  }, [
    activeTradingAccount.personalDailyLossLimit,
    activeTradingAccount.personalDailyLossLimitAction,
    activeTradingAccount.personalDailyProfitTarget,
    activeTradingAccount.personalDailyProfitTargetAction
  ]);

  useEffect(() => {
    resetDailyLimits();
  }, [resetDailyLimits]);

  useEffect(() => {
    setRisk(settings.risk);
    setToMake(settings.toMake);
    setConfirmation(settings.showConfirmations);
    setAutoApply(settings.autoApply);
  }, [settings]);

  const cancel = useCallback(() => {
    setRisk(settings.risk);
    setToMake(settings.toMake);
    setConfirmation(settings.showConfirmations);
    setAutoApply(settings.autoApply);
    setPersonalDailyLossLimit(activeTradingAccount.personalDailyLossLimit);
    resetDailyLimits();
    onCancelled && onCancelled();
  }, [settings, resetDailyLimits]);

  const onSave = useCallback(() => {
    if (personalDailyLossLimitAction != PersonalDailyAction.None && personalDailyLossLimit === null) {
      toast('Please enter a Personal Daily Loss Limit when you the action is not disabled.', { type: 'error' });
      return;
    }

    if (personalDailyProfitTargetAction != PersonalDailyAction.None && personalDailyProfitTarget === null) {
      toast('Please enter a Personal Daily Profit Target when you the action is not disabled.', { type: 'error' });
      return;
    }

    const model: IUserSettingsModel = {
      risk,
      toMake,
      showConfirmations: confirmation,
      autoApply: autoApply,
      autoCenter: settings.autoCenter,
      soundNotifications: settings.soundNotifications,
      custom: new JsonDocument({ rootElement: new RootElement(settings.customSettings) })
    };

    setIsLoading(true);

    const dailyLimtis = new PersonalDailyLimitsRequest({
      tradingAccountId: activeTradingAccount.accountId,
      personalDailyLossLimit: personalDailyLossLimit,
      personalDailyLossLimitAction: personalDailyLossLimitAction,
      personalDailyProfitTarget: personalDailyProfitTarget,
      personalDailyProfitTargetAction: personalDailyProfitTargetAction
    });

    Promise.all([settings.save(model), tradingAccountApi.setPersonalDailyLimits(dailyLimtis)])
      .then((res) => {
        setIsLoading(false);
        onSaved && onSaved();
        toast('Settings saved', { type: 'success', hideProgressBar: true });

        if (!res[0]) {
          logException(new Error('Error saving settings'), 'Error saving settings');
          toast('Error saving settings', { type: 'error', hideProgressBar: true });
        }

        if (!res[1].success) {
          logException(new Error('Error saving daily limits'), 'Error saving daily limits: ' + res[1].errorMessage);
          toast('Error saving daily limits: ' + res[1].errorMessage, { type: 'error', hideProgressBar: true });
        }
      })
      .catch((e) => {
        setIsLoading(false);
        logException(e, 'Error saving settings');
        toast('Error saving settings', { type: 'error', hideProgressBar: true });
      });
  }, [
    risk,
    toMake,
    confirmation,
    autoApply,
    settings.save,
    activeTradingAccount.accountId,
    personalDailyLossLimit,
    personalDailyLossLimit,
    personalDailyLossLimitAction,
    personalDailyProfitTarget,
    personalDailyProfitTargetAction
  ]);

  const updateToMake = useCallback((input: string) => {
    const value = parseInt(input);
    if (value <= 0 || isNaN(value)) {
      setToMake(null);
      return;
    }
    setToMake(Math.max(0, value));
  }, []);

  const updateDailyLoss = useCallback(
    (input: string) => {
      let value = parseInt(input);
      if (value <= 0 || isNaN(value)) {
        setPersonalDailyLossLimit(null);
        return;
      }

      let maxPDLL = activeTradingAccount.dailyLoss || Math.max(100000, Math.max(activeTradingAccount.startingBalance, activeTradingAccount.balance));

      if (activeTradingAccount.type == TradingAccountType.Sim || activeTradingAccount.type == TradingAccountType.None) value = Math.min(value, maxPDLL);

      setPersonalDailyLossLimit(Math.max(0, value));
    },
    [activeTradingAccount.dailyLoss]
  );

  const updatePersonalDailyProfitTarget = useCallback((input: string) => {
    const value = parseInt(input);
    if (value <= 0 || isNaN(value)) {
      setPersonalDailyProfitTarget(null);
      return;
    }
    setPersonalDailyProfitTarget(Math.max(0, value));
  }, []);

  const updateRisk = useCallback((input: string) => {
    const value = parseInt(input);
    if (value <= 0 || isNaN(value)) {
      setRisk(null);
      return;
    }
    setRisk(Math.max(0, value));
  }, []);

  const isChanged = useMemo(() => {
    return (
      risk !== settings.risk ||
      toMake !== settings.toMake ||
      confirmation !== settings.showConfirmations ||
      autoApply !== settings.autoApply ||
      personalDailyLossLimit !== activeTradingAccount.personalDailyLossLimit ||
      personalDailyLossLimit != activeTradingAccount.personalDailyLossLimit ||
      personalDailyLossLimitAction != activeTradingAccount.personalDailyLossLimitAction ||
      personalDailyProfitTarget != activeTradingAccount.personalDailyProfitTarget ||
      personalDailyProfitTargetAction != activeTradingAccount.personalDailyProfitTargetAction
    );
  }, [
    risk,
    toMake,
    confirmation,
    autoApply,
    settings,
    settings.customSettings,
    personalDailyLossLimit,
    personalDailyLossLimitAction,
    personalDailyProfitTarget,
    personalDailyProfitTargetAction,
    activeTradingAccount.personalDailyLossLimit,
    activeTradingAccount.personalDailyLossLimitAction,
    activeTradingAccount.personalDailyProfitTarget,
    activeTradingAccount.personalDailyProfitTargetAction
  ]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        overflowY: 'hidden',
        maxHeight: '100%',
        backgroundColor: isMobile && '#1c1e23'
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          // alignContent: 'space-around',
          paddingBottom: '1em',
          paddingTop: 0,
          maxWidth: '100%',
          overflowY: 'auto'
        }}
      >
        <FormGroup sx={{ margin: '1em', width: '100%', maxWidth: '30em' }}>
          <FormLabel>
            Position Risk / Profit Brackets
            <HelpMessage message="These values act like a traditional 'bracket order', and will automatically enter an order to close out the position if the Risk or Profit trigger is hit. Note: risk trigger is exited with a STOP MARKET order." />
          </FormLabel>
          <TextField
            label='Risk ($)'
            variant='outlined'
            value={risk || ''}
            type='number'
            InputProps={{ inputProps: { min: 0 } }}
            style={{ margin: '1em 0' }}
            onChange={(e) => updateRisk(e.target.value)}
          />
          <TextField
            label='Profit ($)'
            variant='outlined'
            value={toMake || ''}
            type='number'
            InputProps={{ inputProps: { min: 0 } }}
            style={{ margin: '1em 0' }}
            onChange={(e) => updateToMake(e.target.value)}
          />
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={autoApply} onChange={() => setAutoApply(!autoApply)} />} label='Automatically apply Risk / Profit bracket to new Positions' />
          </FormGroup>
        </FormGroup>
        <FormGroup sx={{ margin: '1em', minWidth: '15em', width: '100%', maxWidth: '30em' }}>
          <FormLabel>Order Settings</FormLabel>
          <FormControlLabel control={<Checkbox checked={confirmation} onChange={() => setConfirmation(!confirmation)} />} label='Show Confirmations' />
        </FormGroup>
        {(activeTradingAccount.type == TradingAccountType.Express || activeTradingAccount.type == TradingAccountType.Sim) && (
          <FormGroup sx={{ margin: '1em', minWidth: '15em', width: '100%', maxWidth: '30em' }}>
            <FormLabel sx={{ margin: '1em 0 0.5em 0' }}>
              Personal Daily Loss Limit (PDLL)
              <HelpMessage message='Set a personal daily loss limit on your account.' />
            </FormLabel>
            <TextField
              data-intercom-target='pdll-input'
              variant='outlined'
              required={personalDailyLossLimitAction != PersonalDailyAction.None}
              value={personalDailyLossLimit || ''}
              type='number'
              InputProps={{
                startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                inputProps: {
                  min: 0,
                  max: activeTradingAccount.dailyLoss || 100_000
                }
              }}
              onChange={(e) => updateDailyLoss(e.target.value)}
            />
            <FormLabel sx={{ margin: '1em 0 0.5em 0' }}>
              PDLL Action
              <HelpMessage message='Sets the action that occurs when your personal daily loss limit is triggered.' />
            </FormLabel>
            <Select data-intercom-target='pdll-action' sx={{ width: '18em' }} size='small' onChange={(v) => setPersonalDailyLossLimitAction(v.target.value as any as PersonalDailyAction)} value={personalDailyLossLimitAction}>
              <MenuItem value={PersonalDailyAction.None} key={PersonalDailyAction.None}>
                Do Nothing
              </MenuItem>
              <MenuItem value={PersonalDailyAction.Liquidate} key={PersonalDailyAction.Liquidate}>
                Liquidate
              </MenuItem>
              <MenuItem value={PersonalDailyAction.LiquidateAndBlock} key={PersonalDailyAction.LiquidateAndBlock}>
                Liquidate & Block
              </MenuItem>
            </Select>
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '2em' }}>
              <FormLabel sx={{ margin: '1em 0 0.5em 0' }}>
                Personal Daily Profit Target (PDPT)
                <HelpMessage message='Set a personal daily profit target for your account.' />
              </FormLabel>
              <TextField
                variant='outlined'
                required={personalDailyProfitTargetAction != PersonalDailyAction.None}
                value={personalDailyProfitTarget || ''}
                type='number'
                InputProps={{
                  startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                  inputProps: {
                    min: 0,
                    max: 100000
                  }
                }}
                onChange={(e) => updatePersonalDailyProfitTarget(e.target.value)}
              />
              <FormLabel sx={{ margin: '1em 0 0.5em 0' }}>
                PDPT Action
                <HelpMessage message='Sets the action that occurs when your personal daily profit target is triggered.' />
              </FormLabel>
              <Select style={{ width: '18em' }} size='small' onChange={(v) => setPersonalDailyProfitTargetAction(v.target.value as any as PersonalDailyAction)} value={personalDailyProfitTargetAction}>
                <MenuItem value={PersonalDailyAction.None} key={PersonalDailyAction.None}>
                  Do Nothing
                </MenuItem>
                <MenuItem value={PersonalDailyAction.Liquidate} key={PersonalDailyAction.Liquidate}>
                  Liquidate
                </MenuItem>
                <MenuItem value={PersonalDailyAction.LiquidateAndBlock} key={PersonalDailyAction.LiquidateAndBlock}>
                  Liquidate & Block
                </MenuItem>
              </Select>
            </Box>
          </FormGroup>
        )}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {(isChanged || showButtons) && (
          <StyledButton color='error' disabled={isLoading} onClick={cancel} sx={{ margin: '1em' }}>
            Cancel
          </StyledButton>
        )}
        {isChanged && (
          <StyledButton color='success' disabled={isLoading} onClick={onSave} sx={{ margin: '1em' }}>
            Save {isLoading && <FontAwesomeIcon spin spinPulse icon={faSpinner} />}
          </StyledButton>
        )}
      </Box>
    </Box>
  );
};

export default RiskSettings;
