import { getErrorMessage, logMessage } from 'datafeeds2/lib/helpers';
import { SymbolsStorage } from 'datafeeds2/lib/symbols-storage';
import { DataPulseProvider } from './datapulse';
import { LinkMap } from '@/contexts/LinkedContext';
import { QuotesPulseProvider } from '@/datafeed/quotesPulseProvider';
import { HistoryProvider, ResponseLimit } from './historyProvider';
import { ConfigClient, HistoryBarsResultCode, HistoryClient, MarksClient, SearchClient, SymbolsClient, TimeClient } from '@/api/dataApi';
import {
  ErrorCallback,
  LibrarySymbolInfo,
  Bar,
  SubscribeBarsCallback,
  ResolutionString,
  ResetInfo,
  UdfCompatibleConfiguration,
  GetMarksCallback,
  TimescaleMark,
  UdfDatafeedTimescaleMark,
  ServerTimeCallback,
  SearchSymbolsCallback,
  ResolveCallback,
  SymbolResolveExtension,
  QuotesCallback
} from './dataFeedTypes';
import { IRequester } from 'datafeeds2/src/irequester';
import { QuotesProvider } from './quotesProvider';

/**
 * This class implements interaction with UDF-compatible datafeed.
 * See UDF protocol reference at https://github.com/tradingview/charting_library/wiki/UDF
 */
export class TopStepDataFeed {
  private _configuration: UdfCompatibleConfiguration;
  private _symbolsStorage: SymbolsStorage | null;
  private _datafeedURL: string;
  private _requester: IRequester;
  private _historyProvider: HistoryProvider;
  private _quotesProvider: QuotesProvider;
  private _dataPulseProvider: DataPulseProvider;
  private _quotesPulseProvider: QuotesPulseProvider;
  private _configurationReadyPromise: Promise<void>;
  private _linksMap: LinkMap;
  private _resetCallbacks: ResetInfo[];
  private _disableMarks: boolean;
  private _marksClient: MarksClient;
  private _timeClient: TimeClient;
  private _searchClient: SearchClient;
  private _configClient: ConfigClient;
  private _symbolsClient: SymbolsClient;

  constructor(
    datafeedURL,
    historyClient: HistoryClient,
    marksClient: MarksClient,
    timeClient: TimeClient,
    searchClient: SearchClient,
    configClient: ConfigClient,
    symbolsClient: SymbolsClient,
    quotesProvider: QuotesProvider,
    requester: IRequester,
    subscribeBars: (symbolId: number, resolution: string, guid: string, callback: (data: any) => void) => number,
    unsubscribeBars: (guid: string, callbackId: number) => void,
    links: LinkMap,
    limitedServerResponse?: ResponseLimit,
    mobile?: boolean,
    disableMarks?: boolean
  ) {
    this._resetCallbacks = [];
    this._linksMap = links;
    this._configuration = defaultConfiguration();
    this._symbolsStorage = null;
    this._datafeedURL = datafeedURL;
    this._requester = requester;
    this._historyProvider = new HistoryProvider(historyClient, limitedServerResponse);
    this._quotesProvider = quotesProvider;
    this._marksClient = marksClient;
    this._timeClient = timeClient;
    this._searchClient = searchClient;
    this._configClient = configClient;
    this._symbolsClient = symbolsClient;
    this._dataPulseProvider = new DataPulseProvider(subscribeBars, unsubscribeBars, mobile);
    this._quotesPulseProvider = new QuotesPulseProvider(this._quotesProvider);
    this._configurationReadyPromise = this._requestConfiguration().then((configuration) => {
      if (configuration === null) {
        configuration = defaultConfiguration();
      }
      this._setupWithConfiguration(configuration);
    });
    this._disableMarks = disableMarks;
  }

  setLinksMap(links: LinkMap) {
    this._linksMap = links;
  }

  onReady(callback) {
    this._configurationReadyPromise.then(() => {
      callback(this._configuration);
    });
  }
  disableMarks() {
    this._disableMarks = true;
  }
  enableMarks() {
    this._disableMarks = false;
  }
  getQuotes(symbols: string[], onDataCallback: QuotesCallback, onErrorCallback: (msg: string) => void) {
    //  onErrorCallback('Not implemented');
    //   this._quotesProvider.getQuotes(symbols).then(onDataCallback).catch(onErrorCallback);
  }

  subscribeQuotes(symbols: string[], fastSymbols: string[], onRealtimeCallback: (e) => void, listenerGuid: string) {
    // // tv is sending garbage so we have to clean it up
    // const cleanedFast = fastSymbols.map(item => {
    //   // Find the position of the start and end of the symbol value
    //   const start = item.indexOf('"/') + 1;  // Adjust index to get from the start of the value
    //   const end = item.indexOf('"', start + 1);
    //   return item.substring(start, end);
    // });
    // this._quotesPulseProvider.subscribeQuotes(symbols, cleanedFast, onRealtimeCallback, listenerGuid);
  }
  unsubscribeQuotes(listenerGuid) {
    //  this._quotesPulseProvider.unsubscribeQuotes(listenerGuid);
  }

  // we use timescale marks instead
  getMarks(symbolInfo, from, to, onDataCallback, resolution) {
    return;
  }

  getTimescaleMarks(symbolInfo: LibrarySymbolInfo, from: number, to: number, onDataCallback: GetMarksCallback<TimescaleMark>, resolution: ResolutionString) {
    if (!this._configuration.supports_timescale_marks || this._disableMarks) {
      return;
    }
    const requestParams = {
      symbol: symbolInfo.ticker || '',
      from: from,
      to: to,
      resolution: resolution
    };

    this._marksClient
      .get(requestParams.symbol, requestParams.from, requestParams.to, requestParams.resolution)
      .then((response) => {
        onDataCallback(
          response.map((x) => {
            return {
              id: x.id,
              time: x.time,
              color: x.color,
              label: x.label,
              tooltip: x.tooltip
            };
          })
        );
      })
      .catch((error) => {
        logMessage(`UdfCompatibleDatafeed: Request timescale marks failed: ${getErrorMessage(error)}`);
        onDataCallback([]);
      });
  }

  getServerTime(callback: ServerTimeCallback) {
    if (!this._configuration.supports_time) {
      return;
    }

    this._timeClient
      .get()
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        logMessage(`UdfCompatibleDatafeed: Fail to load server time, error=${getErrorMessage(error)}`);
      });
  }

  searchSymbols(userInput: string, exchange: string, symbolType: string, onResult: SearchSymbolsCallback) {
    if (this._configuration.supports_search) {
      const params = {
        limit: 30 /* Constants.SearchItemsLimit */,
        query: userInput.toUpperCase(),
        type: symbolType,
        exchange: exchange
      };

      this._searchClient
        .get(params.query, params.limit, params.exchange)
        .then((response) => {
          onResult(response);
        })
        .catch((error) => {
          logMessage(`UdfCompatibleDatafeed: Search symbols for '${userInput}' failed. Error=${getErrorMessage(error)}`);
          onResult([]);
        });
    } else {
      if (this._symbolsStorage === null) {
        throw new Error('UdfCompatibleDatafeed: inconsistent configuration (symbols storage)');
      }
      this._symbolsStorage.searchSymbols(userInput, exchange, symbolType, 30 /* Constants.SearchItemsLimit */).then(onResult).catch(onResult.bind(null, []));
    }
  }

  resolveSymbol(symbolName: string, onResolve: ResolveCallback, onError: ErrorCallback, extension?: SymbolResolveExtension, ...args) {
    logMessage('Resolve requested', args);
    const currencyCode = extension && extension.currencyCode;
    const unitId = extension && extension.unitId;
    const resolveRequestStartTime = Date.now();
    const links = Object.values(this._linksMap).filter((y) => symbolName.includes(y.text));
    const link = links.length > 0 ? links[0] : null;

    if (link) {
      symbolName = link.text + link.symbol.friendlyName;
    }

    function onResultReady(symbolInfo: LibrarySymbolInfo) {
      logMessage(`Symbol resolved: ${Date.now() - resolveRequestStartTime}ms`);
      onResolve(symbolInfo);
    }

    if (!this._configuration.supports_group_request) {
      const params = {
        symbol: symbolName,
        currencyCode: currencyCode ?? undefined,
        unitId: unitId ?? undefined
      };

      this._symbolsClient
        .get(params.symbol)
        .then((response) => {
          if (response.s !== null) {
            console.log('Error resolving symbol: ' + response.s);
            onError('unknown_symbol');

            return;
          }

          const defaultSession = extension?.session ?? 'regular';

          let selected_session = response.subsessions.find((x) => x.id === defaultSession);
          if (!selected_session) {
            selected_session = response.subsessions[0];
          }

          const symbolInfo: LibrarySymbolInfo = {
            ...response,
            name: `${!!link ? link.text : response.name}`,
            base_name: [response.exchangeListed + ':' + response.name],
            actualTicker: response.ticker,
            ticker: symbolName,
            description: !!link ? `${link.text} ${link.symbol.friendlyName} - ${link.symbol.description}` : response.description,
            watermarkTitle: response.ticker,
            watermarkDescription: response.description,
            full_name: `${response.exchangeTraded}:${response.name}`,
            listed_exchange: response.exchangeListed,
            exchange: response.exchangeTraded,
            has_intraday: response.has_intraday,
            minmov: response.minmov,
            minmove2: response.minmove2,
            session: selected_session.session,
            session_display: selected_session.sessionDisplay,
            supported_resolutions: response.supported_resolutions,
            has_daily: response.has_daily,
            has_weekly_and_monthly: response.has_weekly_and_monthly,
            intraday_multipliers: response.intraday_multipliers ?? ['1', '5', '15', '30', '60'],
            subsession_id: selected_session.id,
            subsessions: response.subsessions.map((y) => y.toJSON())
          } as unknown as LibrarySymbolInfo;

          onResultReady(symbolInfo);
        })
        .catch((error) => {
          logMessage(`UdfCompatibleDatafeed: Error resolving symbol: ${getErrorMessage(error)}`);
          onError('unknown_symbol');
        });
    } else {
      if (this._symbolsStorage === null) {
        throw new Error('UdfCompatibleDatafeed: inconsistent configuration (symbols storage)');
      }
      this._symbolsStorage.resolveSymbol(symbolName, currencyCode, unitId).then(onResultReady).catch(onError);
    }
  }

  resetCache(symbol: string) {
    this._resetCallbacks.forEach((callback) => {
      if (callback.ticker === symbol) {
        callback.callback();
      }
    });
  }

  getBars(symbolInfo: LibrarySymbolInfo, resolution, periodParams, onResult, onError) {
    const linkValues = Object.values(this._linksMap);
    const matchingLinks = linkValues.filter((y) => y.text == symbolInfo.ticker);

    this._historyProvider
      .getBars(symbolInfo, resolution, periodParams)
      .then((result) => {
        let meta = result.code === HistoryBarsResultCode.NoData ? { noData: true } : { noData: false };

        const mapped: Bar[] = result.bars.map((x) => {
          const volumePresent = x.v !== undefined;
          const ohlPresent = x.o !== undefined;

          let values = {
            time: x.t,
            close: x.c,
            open: x.c,
            high: x.c,
            low: x.c,
            volume: undefined
          };

          if (ohlPresent) {
            values.open = x.o;
            values.high = x.h;
            values.low = x.l;
          }

          if (volumePresent) {
            values.volume = x.v;
          }

          return values;
        });

        onResult(mapped, meta);
      })
      .catch(onError);
  }

  subscribeBars(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, onTick: SubscribeBarsCallback, listenerGuid: string, _onResetCacheNeededCallback: () => void) {
    this._resetCallbacks.push({ callback: _onResetCacheNeededCallback, guid: listenerGuid, ticker: symbolInfo.ticker });
    this._dataPulseProvider.subscribeBars(symbolInfo, resolution, onTick, listenerGuid);
  }

  unsubscribeBars(listenerGuid) {
    this._resetCallbacks = this._resetCallbacks.filter((x) => x.guid !== listenerGuid);
    this._dataPulseProvider.unsubscribeBars(listenerGuid);
  }

  _requestConfiguration() {
    return this._configClient
      .get()
      .then((response) => {
        if (response === null) {
          throw new Error('UdfCompatibleDatafeed: Cannot get datafeed configuration');
        }
        this._setupWithConfiguration(response as unknown as UdfCompatibleConfiguration);
        return response as unknown as UdfCompatibleConfiguration;
      })
      .catch((error) => {
        logMessage(`UdfCompatibleDatafeed: Cannot get datafeed configuration - use default, error=${getErrorMessage(error)}`);
        return null;
      });
  }

  _setupWithConfiguration(configurationData: UdfCompatibleConfiguration) {
    this._configuration = configurationData;
    if (configurationData.exchanges === undefined) {
      configurationData.exchanges = [];
    }
    if (!configurationData.supports_search && !configurationData.supports_group_request) {
      throw new Error('Unsupported datafeed configuration. Must either support search, or support group request');
    }
    if (configurationData.supports_group_request || !configurationData.supports_search) {
      this._symbolsStorage = new SymbolsStorage(this._datafeedURL, configurationData.supported_resolutions || [], this._requester);
    }
    logMessage(`UdfCompatibleDatafeed: Initialized with ${JSON.stringify(configurationData)}`);
  }
}
function defaultConfiguration(): UdfCompatibleConfiguration {
  return {
    supports_search: false,
    supports_group_request: true,
    supported_resolutions: ['1' as ResolutionString, '5' as ResolutionString, '15' as ResolutionString, '30' as ResolutionString, '60' as ResolutionString, '1D' as ResolutionString, '1W' as ResolutionString, '1M' as ResolutionString, '1T' as ResolutionString],
    supports_marks: false,
    supports_timescale_marks: false
  };
}
