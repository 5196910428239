import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState } from 'react';
import { useOrders } from '../../../../contexts/OrdersContext';
import { PositionModel } from '../../../../api/userApi';
import styles from './positions.module.scss';
import { ContractPriceSpan, CurrencySpan, DateSpan, RedGreenCurrencySpan } from '@components/numbers';
import { Box, FormLabel, withTheme } from '@mui/material';
import { DataGridPro, GridColDef, GridValueGetterParams, useGridApiRef } from '@mui/x-data-grid-pro';
import { logException } from '@/helpers/exceptionHelper';
import _ from 'lodash';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import CloseIcon from '@mui/icons-material/Close';
import { StyledButton } from '@/components/styledComponents';
import { useSymbol } from '@/contexts/SymbolContext';
import { useDeviceContext } from '@/contexts/DeviceContext';
interface PositionsProps {
  changed: () => void;
  tabData: {
    gridState: GridInitialStatePro;
  };
}

const Positions: React.FC<PositionsProps> = (props): JSX.Element => {
  // const [activeButton, setActiveButton] = useState('trades');
  const { closePosition, editRiskToMake } = useOrders();
  const { getContractById } = useSymbol();
  const { isMobile } = useDeviceContext();
  const gridRef = useGridApiRef();

  const { activePositions } = useOrders();

  // const [editData, setEditData] = useState<SltpData>(null);
  const [closing, setClosing] = useState(false);
  // const saveEditData = useMemo(async () => {
  //   console.log("triggered here 1", editData)
  //   if (!editData) {
  //     return;
  //   }
  //   editRiskToMake(editData.positionId, editData.risk, editData.toMake);
  //   setEditData(null);
  // }, [editData]);

  const handleClosePosition = async (pos: PositionModel) => {
    if (closing) return;
    try {
      setClosing(true);
      await closePosition(pos.symbolId);
    } catch (e) {
      logException(e, 'Failed to close position');
    } finally {
      setClosing(false);
      // props.setRefresh(true);
    }
  };

  const columns: GridColDef<PositionModel>[] = useMemo(() => {
    return [
      {
        field: 'entryTime',
        headerName: 'Time',
        sortable: true,
        filterable: true,
        flex: 0.7,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<PositionModel>) => {
          return <DateSpan>{params.row.entryTime.local()}</DateSpan>;
        },
        minWidth: isMobile ? 150 : undefined
      },
      {
        field: 'symbolName',
        headerName: 'Symbol',
        sortable: true,
        flex: 0.4,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<PositionModel>) => {
          return <span>{params.row.symbolName}</span>;
        },
        align: 'left',
        headerAlign: 'left',
        minWidth: isMobile ? 100 : undefined
      },
      {
        field: 'positionSize',
        headerName: 'Position',
        sortable: true,
        filterable: true,
        flex: 0.5,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<PositionModel>) => {
          return <span>{params.row.positionSize}</span>;
        },
        align: 'center',
        headerAlign: 'center',
        minWidth: isMobile ? 100 : undefined
      },
      {
        field: 'averagePrice',
        headerName: 'Entry Price',
        sortable: true,
        filterable: true,
        flex: 0.3,
        type: 'number',
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<PositionModel>) => {
          const contract = getContractById(params.row.symbolId);
          return <ContractPriceSpan contract={contract}>{params.row.averagePrice}</ContractPriceSpan>;
        },
        align: 'center',
        headerAlign: 'center',
        minWidth: isMobile ? 110 : undefined
      },
      {
        field: 'risk',
        headerName: 'Risk',
        filterable: true,
        sortable: true,
        flex: 1,
        type: 'number',
        headerClassName: styles.header,
        editable: true,
        renderCell: (params: GridValueGetterParams<PositionModel>) => {
          return (
            <span>
              <CurrencySpan>{params.row.risk}</CurrencySpan>
              <FontAwesomeIcon icon={faPencil} style={{ cursor: 'pointer', marginLeft: '1em' }} />
            </span>
          );
        },
        align: 'center',
        headerAlign: 'center',
        minWidth: isMobile ? 100 : undefined
      },
      {
        field: 'toMake',
        headerName: 'To Make',
        sortable: true,
        type: 'number',
        flex: 0.5,
        editable: true,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<PositionModel>) => {
          return (
            <span>
              <CurrencySpan>{params.row.toMake}</CurrencySpan>
              <FontAwesomeIcon icon={faPencil} style={{ cursor: 'pointer', marginLeft: '1em' }} />
            </span>
          );
        },
        align: 'center',
        headerAlign: 'center',
        minWidth: isMobile ? 100 : undefined
      },
      {
        field: 'profitAndLoss',
        headerName: 'P&L',
        sortable: true,
        flex: 0.7,
        type: 'number',
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<PositionModel>) => {
          return <RedGreenCurrencySpan colorCheck={params.row.profitAndLoss}>{params.row.profitAndLoss}</RedGreenCurrencySpan>;
        },
        align: 'center',
        headerAlign: 'center',
        minWidth: isMobile ? 100 : undefined
      },
      {
        field: 'close',
        headerName: 'Close',
        sortable: true,
        flex: 0.7,
        type: 'number',
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<PositionModel>) => {
          return (
            <StyledButton onClick={() => handleClosePosition(params.row)} variant='text' className={styles.removeButton}>
              <CloseIcon />
            </StyledButton>
          );
        },
        align: 'center',
        headerAlign: 'center',
        minWidth: isMobile ? 100 : undefined
      }
    ];
  }, [isMobile]);

  const noRows = useMemo(() => {
    return (
      <Box height='100%' width='100%' display='flex' alignItems='center' justifyContent='center'>
        No Positions
      </Box>
    );
  }, []);

  const saveNewValues = async (newRow: any, originalRow: any) => {
    editRiskToMake(newRow.id, newRow.risk, newRow.toMake);
    return newRow;
  };

  const initialState: GridInitialStatePro = useMemo(() => {
    return (
      props?.tabData?.gridState ?? {
        sorting: {
          sortModel: [
            {
              field: 'symbol',
              sort: 'desc'
            }
          ]
        },
        pagination: {
          paginationModel: { page: 0, pageSize: 20 }
        }
      }
    );
  }, []);

  return (
    <Box style={{ height: '100%', width: '100%', minWidth: '100px', minHeight: '100px', maxHeight: isMobile ? 'calc(100% - 40px)' : undefined }}>
      {isMobile && (
        <div style={{ padding: '0.75em', textAlign: 'center' }}>
          <FormLabel sx={{ fontSize: '1.35em' }}>Positions</FormLabel>
        </div>
      )}
      <DataGridPro
        apiRef={gridRef}
        getRowId={(row) => row.id}
        rowHeight={32}
        columnHeaderHeight={32}
        rows={activePositions.filter((y) => y.positionSize != 0)}
        density='compact'
        columns={columns}
        processRowUpdate={(updatedRow, originalRow) => saveNewValues(updatedRow, originalRow)}
        slots={{
          noRowsOverlay: () => noRows
        }}
        initialState={initialState}
        onStateChange={() => {
          const newState = gridRef.current.exportState();
          if (_.isEqual(newState, props.tabData.gridState)) return;

          props.tabData.gridState = newState;
          props.changed();
        }}
        pageSizeOptions={[20, 50]}
      ></DataGridPro>
    </Box>
  );
};

export default Positions;
