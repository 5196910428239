import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import styles from './layoutSelector.module.scss';
import { IExtendedTradingAccountModel, useTradingAccount } from '../../contexts/TradingAccountContext';
import { useApi } from '../../contexts/ApiContext';
import { ITradingAccountModel, SetAccountNicknameRequest, SetAccountNicknameResultCode, TradingAccountStatus, TradingAccountType } from '../../api/userApi';
import { Box, Button, IconButton, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { tradingAccountStatusMap, violationTypeMap } from 'src/data/enumTypeMaps';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonWalkingArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { faPersonWalkingArrowLoopLeft } from '@fortawesome/pro-solid-svg-icons';
import copyTradingStyles from './copyTrading.module.scss';
import { useDeviceContext } from '@/contexts/DeviceContext';
import { config, TradingPlatforms } from '@/config';
import { useSettings } from '@contexts/SettingsContext';
import EditIcon from '@mui/icons-material/Edit';
import { TsModal } from '../modal';
import { toast, UpdateOptions } from 'react-toastify';

interface AccountSelectorProps {
  style?: React.CSSProperties;
}
const AccountSelector: React.FC<AccountSelectorProps> = (props): JSX.Element => {
  const { activeTradingAccount, tradingAccounts, setActiveTradingAccount, getAccountTemplateData, tradingRules } = useTradingAccount();
  const { isMobile } = useDeviceContext();
  const { customSettings } = useSettings();
  const [nicknameModalAccount, setNicknameModalAccount] = useState<ITradingAccountModel>(null);
  const [newNickname, setNewNickname] = useState('');
  const { tradingAccountApi } = useApi();

  const handleOnChange = useCallback(
    (e: SelectChangeEvent) => {
      const account = tradingAccounts.find((x) => x.accountId.toString() === e.target.value.toString());
      if (account) {
        setActiveTradingAccount(account);
      }
    },
    [tradingAccounts, setActiveTradingAccount]
  );

  const getAccountType = useCallback((tradingAccount: ITradingAccountModel) => {
    if (tradingAccount.templateId) {
      const template = getAccountTemplateData(tradingAccount.templateId);
      if (template && template.template.title != null && template.template.title !== '') {
        return template.template.title;
      }

      return ' LOADING ';
    }
    switch (tradingAccount.type) {
      case TradingAccountType.Express:
        return `$${tradingAccount.combineName} ${config.accountTypes.funded}`;
      case TradingAccountType.Live:
        return config.accountTypes.live;
      case TradingAccountType.None:
      case TradingAccountType.Sim:
        return `$${tradingAccount.combineName} ${config.accountTypes.sim}`;
      case TradingAccountType.Practice:
        return `$${tradingAccount.combineName} ${config.accountTypes.practice}`;
    }
  }, [getAccountTemplateData]);

  const accountColor = useCallback((tradingAccount: ITradingAccountModel) => {
    if (tradingAccount.templateId) {
      const template = getAccountTemplateData(tradingAccount.templateId);
      if (template && template.template.titleColor != null && template.template.titleColor !== '') {
        return template.template.titleColor;
      }

      return '#FFFFFF';
    }
    switch (tradingAccount.type) {
      case TradingAccountType.Express:
        return '#FFCB05';
      case TradingAccountType.Live:
        return '#4CB548';
      case TradingAccountType.None:
      case TradingAccountType.Sim:
        return '#E6E8EF';
      case TradingAccountType.Practice:
        return '#0092DB';
    }
  }, [getAccountTemplateData]);

  const handleClickNickname = (e: any, account: ITradingAccountModel) => {
    e.stopPropagation();
    setNicknameModalAccount(account);
    setNewNickname(account.nickname ?? '');
  };

  const handleSaveNickname = () => {
    setNicknameModalAccount(null);
    setNewNickname('');

    const promise = new Promise<void>((resolve, reject) => {
      tradingAccountApi.setAccountNickname(new SetAccountNicknameRequest({
        accountId: nicknameModalAccount.accountId,
        nickname: newNickname
      })).then((res) => {
        if (res === SetAccountNicknameResultCode.Success) {
          const updatedAccount = tradingAccounts.find((x) => x.accountId === nicknameModalAccount.accountId);
          updatedAccount.nickname = newNickname;

          resolve();
        }

        if (res === SetAccountNicknameResultCode.NameAlreadyUsed) {
          reject('Nickname already used');
        }

        if (res === SetAccountNicknameResultCode.NameTooLong) {
          reject('Nickname too long');
        }

        reject('Error saving nickname');
      });
    });

    toast.promise(promise, {
      pending: 'Saving nickname...',
      success: 'Nickname saved',
      error: {
        render({ data }) {
          // When the promise reject, data will contains the error
          return <span>{data.toString()}</span>
        }
      }
    });
  };

  const handleDeleteNickname = () => {
    setNicknameModalAccount(null);
    setNewNickname('');

    tradingAccountApi.setAccountNickname(new SetAccountNicknameRequest({
      accountId: nicknameModalAccount.accountId,
      nickname: null
    })).then((res) => {
      if (res === SetAccountNicknameResultCode.Success) {
        const updatedAccount = tradingAccounts.find((x) => x.accountId === nicknameModalAccount.accountId);
        updatedAccount.nickname = '';
      }
    });
  };

  const handleCancelNickname = () => {
    setNicknameModalAccount(null);
    setNewNickname('');
  };

  const renderTradingAccount = useCallback((x: IExtendedTradingAccountModel, selected: boolean = false) => {
    let sx = selected ? { padding: 0 } : {};

    const hasViolation = !!x.activeViolation;
    const getViolationText = () => {

      if (!x.activeViolation) return '';

      if (x.activeViolation.customViolation) {
        return x.activeViolation.customViolation.title;
      }

      if (x.templateId) {
        const template = getAccountTemplateData(x.templateId);
        if (template) {
          const rule = tradingRules.find(y => y.id === x.activeViolation.ruleId);
          if (rule) {
            return `${rule.name} Violation`;
          }
        }
      }

      return violationTypeMap[x.activeViolation.type] || 'Violation';

    }

    const AccountTemplateAndName = () => {
      switch (config.platform) {
        case TradingPlatforms.TickTickTrader:
          return (
            <>
              <span style={{ color: accountColor(x), textTransform: 'uppercase', minWidth: '7em', textAlign: 'right', fontWeight: 'bold' }}>
                {x.nickname ? (
                <span>{x.nickname} </span>
              ) : (
                <span>{customSettings.streamerModeAccountName ? '******' : x.accountName}</span>
              )}
              </span>
            </>
          );
        default:
          return (
            <>
              <span style={{ color: accountColor(x), textTransform: 'uppercase', minWidth: '7em', textAlign: 'right', fontWeight: 'bold' }}>
                {getAccountType(x)}
              </span>
              <span style={{ padding: '0 0.4em' }}>|</span>

              {x.nickname ? (
                <span>{x.nickname} </span>
              ) : (
                <span>{customSettings.streamerModeAccountName ? '******' : x.accountName}</span>
              )}
            </>
          );
      }
    };

    const TradeCopierIcon = ({ isFollower, isLeader }) => {
      if (!isFollower && !isLeader) return null;

      const iconStyle = {
        width: '3em',
        marginLeft: '0.5em',
      };

      return isFollower ? (
        <div style={{ display: 'inline-block' }}>
          <span className={copyTradingStyles.follower} style={iconStyle}>
            <FontAwesomeIcon icon={faPersonWalkingArrowLoopLeft} style={{ marginRight: 3 }} /> F
          </span>
        </div>
      ) : (
        <div style={{ display: 'inline-block' }}>
          <span className={copyTradingStyles.leader} style={iconStyle}>
            <FontAwesomeIcon icon={faPersonWalkingArrowRight} style={{ marginRight: 3 }} /> L
          </span>
        </div>
      );
    };

    return (
      <MenuItem value={x.accountId} key={x.accountId} sx={sx}>
        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>

          {!selected && (
            <IconButton
              style={{ padding: 0, margin: '0 0.5em 0.1em 0', color: 'gray', cursor: 'pointer' }}
              onClick={(e) => handleClickNickname(e, x)}
            >
              <EditIcon sx={{ fontSize: '0.8em' }} />
            </IconButton>
          )}
          <AccountTemplateAndName/>
          {x.status != TradingAccountStatus.Active && !hasViolation && <span style={{ marginLeft: '0.3em' }}>({tradingAccountStatusMap[x.status]}) </span>}

          {x.nickname ? <span>({customSettings.streamerModeAccountName ? '******' :x.accountName})</span> : null}

          {hasViolation && <span style={{ marginLeft: '0.3em' }}>({getViolationText()})</span>}
          {!selected && <TradeCopierIcon isFollower={x.isFollower} isLeader={x.isLeader} />}
        </Box>
      </MenuItem>
    );
  }, [customSettings.streamerModeAccountName, getAccountTemplateData, tradingRules, getAccountType, accountColor]);

  const renderAccounts = useMemo(() => {
    const menuitems = [];

    for (const acc of tradingAccounts) {
      if (acc.isLeader) {
        menuitems.push(renderTradingAccount(acc));
        for (const acc of tradingAccounts) {
          if (acc.isFollower) {
            menuitems.push(renderTradingAccount(acc));
          }
        }
      } else if (acc.isFollower) {
        continue;
      } else {
        menuitems.push(renderTradingAccount(acc));
      }
    }
    return menuitems;
  }, [tradingAccounts, renderTradingAccount]);

  return useMemo(
    () => (
      <>
        <div className={classNames(styles.wrapper)} style={props.style}>
          <Select
            className={classNames(isMobile && styles.mobileSelect)}
            // SelectDisplayProps={{ style: { padding: '0.5em' } }}
            style={{ width: '100%' }}
            size='small'
            onChange={handleOnChange}
            value={activeTradingAccount?.accountId + ''}
            renderValue={(value) => {
              const acc = tradingAccounts.find((y) => y.accountId.toString() == value);
              if (!acc) return <span>None</span>;
              return renderTradingAccount(acc, true);
            }}
            MenuProps={
              isMobile
                ? {
                    style: {
                      maxHeight: '50vh'
                    }
                  }
                : undefined
            }
          >
            {renderAccounts}
          </Select>
        </div>
        <TsModal
          sx={{ position: 'fixed' }}
          open={nicknameModalAccount != null}
          onClose={() => setNicknameModalAccount(null)}
        >
          <>
            <Typography variant='h5' color='white'>Edit Account Nickname for {nicknameModalAccount?.accountName}</Typography>
            <TextField
              sx={{ width: '100%', padding: '0.1em', margin: '1em 0' }}
              value={newNickname}
              placeholder='Enter custom nickname...'
              onChange={(e) => setNewNickname(e.target.value)}
              variant='outlined'
              size='small'
            />
            <Box display='flex' flexDirection='row' justifyItems={'stretch'} justifyContent='space-between' width="100%">
              <Box sx={{ flexGrow: 1, width: '100%', textAlign: 'left' }}>
                <Button
                  color='neutral'
                  variant='contained'
                  onClick={handleCancelNickname}
                >
                  Cancel
                </Button>
              </Box>
              <Box sx={{ flexGrow: 1, width: '100%', textAlign: 'center' }}>
                {nicknameModalAccount?.nickname && (
                  <Button
                    color='secondary'
                    variant='contained'
                    onClick={handleDeleteNickname}
                  >
                    Delete
                  </Button>
                )}
              </Box>
              <Box sx={{ flexGrow: 1, width: '100%', textAlign: 'right' }}>
                {newNickname !== nicknameModalAccount?.nickname && newNickname.length > 0 && (
                  <Button
                    color='success'
                    variant='contained'
                    onClick={handleSaveNickname}
                  >
                    Save
                  </Button>
                )}
              </Box>
            </Box>
          </>

        </TsModal>
      </>
    ),
    [isMobile, tradingAccounts, activeTradingAccount, handleOnChange, props.style, renderAccounts, nicknameModalAccount, newNickname, renderTradingAccount]
  );
};

export default React.memo(AccountSelector);
