import React, { useCallback, useMemo, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import LayoutSelector from '../components/topstep/layoutSelector';
import AccountSelector from '../components/topstep/accountSelector';
import Balance from '../components/topstep/balance';
import StrapiAlert from '@/components/topstep/strapiAlert';
import UnrealizedPnl from '../components/topstep/unrealizedPnl';
import RealizedPnl from '../components/topstep/realizedPnl';
import MaxLoss from '../components/topstep/maxLoss';
import DailyLoss from '../components/topstep/dailyLossTargetBar';
import CopyTradingIndicator from '../components/topstep/copyTradingIndicator';
import Button, { ButtonType } from '../components/topstep/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowRestore, faOctagonExclamation } from '@fortawesome/pro-regular-svg-icons';
import { useThemeSelector } from '../contexts/ThemeContext';
import { Box, Dialog, Tooltip } from '@mui/material';
import { useTradingAccount } from '../contexts/TradingAccountContext';

import { Row } from './LayoutComponents';
import styles from './UserLayout.module.scss';
import Ping from '@/components/topstep/ping';
import { useApi } from '@/contexts/ApiContext';
import { PersonalDailyAction, TradingAccountStatus, TradingAccountType, ITradingAccountModel } from '@/api/userApi';
import DailyProfitTargetBar from '@/components/topstep/dailyProfitTargetBar';
import { StyledButton } from '@/components/styledComponents';

interface Props {
  showLayoutSelector?: boolean;
}

export const Navbar: React.FC<Props> = ({ showLayoutSelector }): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const { activeTradingAccount } = useTradingAccount();
  const { tradingAccountApi } = useApi();

  const showNewLayoutModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const canStopTrade = useMemo(() => {
    return !activeTradingAccount?.isFollower && (activeTradingAccount?.status === TradingAccountStatus.Active || activeTradingAccount?.status == TradingAccountStatus.Ineligible) && activeTradingAccount.type != TradingAccountType.Practice;
  }, [activeTradingAccount?.isFollower, activeTradingAccount?.status, activeTradingAccount.type]);

  const getAccountName = (account: ITradingAccountModel): string => {
    if (account){
      if (account.nickname) {
        return account.nickname + ' (' + account.accountName + ')';
      } else {
        return account.accountName;
      }
    } else {
      return 'Unknown Account';
    }
  };

  return (
    <>
      <StrapiAlert />
      <Row width='100%' bgcolor='darkBg' justifyContent='space-between' zIndex={99999} padding='5px 0' margin={0}>
        <Row justifyContent='left' gap='.5em' data-intercom-target='navbar'>
          <AccountSelector style={{ maxWidth: '18em' }} />
          <CopyTradingIndicator />
          <Balance />
          <MaxLoss />
          <RealizedPnl />
          <UnrealizedPnl />
          <DailyLoss />
          {activeTradingAccount?.personalDailyProfitTargetAction > PersonalDailyAction.None && <DailyProfitTargetBar />}
          {canStopTrade && (
            <div data-intercom-target='manual-lock-out' className={styles.stopSignBg} onClick={showNewLayoutModal}>
              <Tooltip title='Manual Lock-Out' style={{ color: 'white' }}>
                <FontAwesomeIcon icon={faOctagonExclamation} />
              </Tooltip>
            </div>
          )}
        </Row>
        <Row justifyContent='right'>
          {/* <IconButton sx={{ ml: 1 }} onClick={toggleSelectedTheme} color='inherit'>
          {selectedTheme === ThemeNames.dark ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton> */}
          <Ping />
          {showLayoutSelector && <LayoutSelector />}
        </Row>
        {showModal && (
          <Dialog onClose={() => setShowModal(false)} open={showModal}>
            <Box sx={{ display: 'flex', flexDirection: 'column', padding: '1em' }}>
              <div className={styles.modalHeader}>
                Confirm Trading <strong>Lock-Out</strong>?
              </div>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', padding: '1em', paddingTop: 0 }}>
              <div className={styles.modalBody}>
                By clicking yes below, you will be <strong>Locked Out</strong> from trading any more until the next market open (5pm CT) on this account ({getAccountName(activeTradingAccount)}). <br /> <br />
                Any open positions or working orders will be flattened / canceled automatically & any copy traded follower positions will be exited. You will not be able to trade this account for the remainder of the trading day!
                <br />
                <br />
                Warning: This action is irreversible.
              </div>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', padding: '1em', justifyContent: 'center' }}>
              <StyledButton color='neutral' onClick={() => setShowModal(false)} style={{ marginRight: '1em' }}>
                No, Cancel
              </StyledButton>
              <StyledButton
                color='error'
                onClick={() => {
                  if (canStopTrade) {
                    tradingAccountApi.stopTrading(activeTradingAccount.accountId);
                    setShowModal(false);
                  }
                }}
              >
                Yes, Lock Me Out
              </StyledButton>
            </Box>
          </Dialog>
        )}
      </Row>
    </>
  );
};
