import React, { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css';
import { useBeforeUnload, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { NavRoutes } from '../data/NavRoutes';
import { ReactComponent as Candles } from '../assets/images/candles.svg';
import { ReactComponent as People } from '../assets/images/people.svg';
import { ReactComponent as Settings } from '../assets/images/cog.svg';
import logoutImg from '../assets/images/logout.png';
import logoImg from '../assets/images/topstep.png';
import { Box, Link, Tooltip, Typography, styled } from '@mui/material';
import { Column } from './LayoutComponents';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import AutoGraph from '@mui/icons-material/AutoGraph';
import { TradingPlatforms, config } from '@/config';
import { TradingEnvironment } from '@/data/tradingEnvironment';
import { useTradingAccount } from '@/contexts/TradingAccountContext';
import { TsModal } from '@/components/modal';
import Button from '@/components/topstep/button';
import { useOrders } from '@/contexts/OrdersContext';
import { OrderStatus } from '@/api/userApi';
import axios from 'axios';
import { useApi } from '@/contexts/ApiContext';

export const Sidebar: React.FC = (): JSX.Element => {
  const { logOut } = useAuth();
  const navigate = useNavigate();
  const { tradingEnvironment } = useTradingAccount();
  const { strapiApi } = useApi();
  const currentPage = window.location.pathname;

  const [showLogoutModal, setShowLogoutModal] = React.useState(false);
  const [showQuitModal, setShowQuitModal] = React.useState(false);
  const [youtube, setYoutube] = React.useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [hideLogout, setHideLogout] = React.useState(searchParams.get('hideLogout') === 'true');

  const hasPositionsOrOrders = () => {
    let hasPosition = false;

    if (!!activePositions) {
      for (let pos of activePositions) {
        if (pos.positionSize !== 0) {
          hasPosition = true;
        }
      }
    }

    let hasOrder = false;

    if (!!orders) {
      for (let order of orders) {
        if (order.status === OrderStatus.Open) {
          hasOrder = true;
        }
      }
    }

    return hasOrder || hasPosition;
  };

  /*
  useEffect(() => {
    // Hook tab close event and prevent closing
    window.addEventListener('beforeunload', (e) => {
      if (hasPositionsOrOrders()) {
        setShowQuitModal(true);
        e.preventDefault();
        e.returnValue = 'You have active positions. Are you sure you want to leave?';
      }
    });
  }, []);
  */

  const onClickLogout = () => {
    if (hasPositionsOrOrders()) {
      setShowLogoutModal(true);
      return;
    }

    logOut();
  };

  const flattenAllThenLogout = () => {
    closeAllPositions();
    logOut();
  };

  const { activePositions, orders, closeAllPositions } = useOrders();

  useEffect(() => {
    const platform = config.platform;
    if (platform === TradingPlatforms.TopstepX) {
      const fetchData = () => {
        strapiApi
          .youtubeUrl()
          .then((res) => {
            setYoutube(res);
          })
          .catch((err) => {
            console.log('Cant retrieve youtube url', err);
          });
      };

      // Fetch data initially
      fetchData();

      // Fetch data every 60 seconds
      const interval = setInterval(fetchData, 60000);

      // Clean up the interval on component unmount
      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    if (searchParams.get('hideLogout')) {
      searchParams.delete('hideLogout');
      setSearchParams(searchParams);
    }
  }, []);

  const platformMenus = useMemo(() => {
    switch (config.platform) {
      case TradingPlatforms.TopstepX:
        return [
          <MenuItem key='tsx-tv' hoverable onClick={() => {}}>
            <Tooltip title='TopstepTV' placement='right' data-intercom-target='topstep-tv'>
              <Box>
                <Link
                  href={`https://www.youtube.com/embed/${youtube}`}
                  target='popup'
                  onClick={() => {
                    window.open(`https://www.youtube.com/embed/${youtube}`, 'popup', 'width=800,height=450,scrollbars=no');
                    return false;
                  }}
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  fontFamily='TopstepFont'
                  fontSize='1.7em'
                  borderRadius='0.4em'
                  padding='0 0.35em'
                  bgcolor='rgba(255,255,255, 0.3)'
                  color='lightBg'
                  underline='none'
                  sx={{
                    '&:hover': {
                      color: 'lightBg',
                      bgcolor: '#99ff00'
                    }
                  }}
                >
                  TV
                </Link>
              </Box>
            </Tooltip>
          </MenuItem>,
          <MenuItem key='tsx-support' onClick={() => navigate(NavRoutes.Support)} data-active={currentPage == NavRoutes.Support} hoverable>
            <Tooltip title='Support' placement='right'>
              <LiveHelpIcon sx={{ fontSize: '3em' }} />
            </Tooltip>
          </MenuItem>
        ];
      case TradingPlatforms.AlphaFutures:
      case TradingPlatforms.E8:
      case TradingPlatforms.OneOfOne:
      case TradingPlatforms.FundingFutures:
      case TradingPlatforms.TickTickTrader:
      case TradingPlatforms.GoatFunded:
      case TradingPlatforms.FuturesElite:
      case TradingPlatforms.FuturesDesk:
      default:
        return [
          <MenuItem key='support' onClick={() => navigate(NavRoutes.Support)} data-active={currentPage == NavRoutes.Support} hoverable>
            <Tooltip title='Support' placement='right'>
              <LiveHelpIcon sx={{ fontSize: '3em' }} />
            </Tooltip>
          </MenuItem>
        ];
    }
  }, [config.platform, youtube]);

  return (
    <Column flex={0} alignItems='center' justifyContent='space-evenly' bgcolor='lightBg'>
      <div style={{ height: '100%' }}>
        <Box height={40} margin='1em' marginBottom='2em'>
          <img src={config.platformSideBarLogo} style={{ width: 40 }} />
        </Box>
        <Column alignItems='center' justifyContent='center'>
          <MenuItem onClick={() => navigate(NavRoutes.Trade)} data-active={currentPage == NavRoutes.Trade || currentPage == '/'} hoverable>
            <Tooltip title='Trading' placement='right'>
              <Candles style={{ width: 30 }} />
            </Tooltip>
          </MenuItem>
          <MenuItem onClick={() => navigate(NavRoutes.Resources)} data-active={currentPage == NavRoutes.Resources} hoverable>
            <Tooltip title='Trader Hub' placement='right'>
              <People />
            </Tooltip>
          </MenuItem>
          {tradingEnvironment != TradingEnvironment.Live && (
            <MenuItem onClick={() => navigate(NavRoutes.Stats)} data-active={currentPage == NavRoutes.Stats} hoverable>
              <Tooltip title='Performance Stats' placement='right'>
                <AutoGraph sx={{ fontSize: '3em' }} />
              </Tooltip>
            </MenuItem>
          )}
          <MenuItem onClick={() => navigate(NavRoutes.Settings)} data-active={currentPage == NavRoutes.Settings} hoverable>
            <Tooltip title='Settings' placement='right' data-intercom-target='settings'>
              <Settings />
            </Tooltip>
          </MenuItem>
          {platformMenus}
        </Column>
      </div>
      {!hideLogout && (
        <MenuItem hoverable onClick={onClickLogout}>
          <Tooltip title='Log Out' placement='right'>
            <Box component='img' src={logoutImg} height={40} />
          </Tooltip>
        </MenuItem>
      )}

      <TsModal open={showLogoutModal}>
        <Box textAlign='center'>
          <p style={{ fontSize: '1.5em' }}>You currently have active positions and/or orders in the market. If you log out now, these orders will NOT be automatically cancelled and/or closed.</p>
          <Box marginTop='4em' width='100%' display='flex' flexDirection='row' justifyContent='center' gap='2em'>
            <Button style={{ width: '25%' }} onClick={() => setShowLogoutModal(false)}>
              Review Orders
            </Button>
            <Button
              style={{ width: '25%', backgroundColor: 'red' }}
              onClick={() => {
                setShowLogoutModal(false);
                flattenAllThenLogout();
              }}
            >
              FLATTEN ALL & LOGOUT
            </Button>
            <Button
              style={{ width: '25%' }}
              onClick={() => {
                setShowLogoutModal(false);
                logOut();
              }}
            >
              Logout Anyways
            </Button>
          </Box>
        </Box>
      </TsModal>

      <TsModal open={showQuitModal}>
        <Box textAlign='center'>
          <p style={{ fontSize: '1.5em' }}>You currently have active positions and/or orders in the market. If you close the platform now, these orders will NOT be automatically cancelled and/or closed.</p>
          <Box marginTop='4em' width='100%' display='flex' flexDirection='row' justifyContent='center' gap='2em'>
            <Button style={{ width: '25%' }} onClick={() => setShowQuitModal(false)}>
              Review Orders
            </Button>
            <Button
              style={{ width: '25%', backgroundColor: 'red' }}
              onClick={() => {
                setShowQuitModal(false);
                flattenAllThenLogout();
              }}
            >
              FLATTEN ALL & LOGOUT
            </Button>
          </Box>
        </Box>
      </TsModal>
    </Column>
  );
};

const MenuItem = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hoverable'
})<{ hoverable?: boolean }>(({ hoverable, theme }: { hoverable?: boolean; theme: any }) => ({
  color: theme.palette.mediumGrey,
  padding: '0 0.5em',
  marginTop: '1.5rem',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: hoverable ? 'pointer' : '',
  '&:hover svg': {
    color: hoverable ? 'white' : ''
  },
  '&[data-active=true] svg': {
    color: hoverable ? 'white' : ''
  },
  '&:hover': {
    color: hoverable ? theme.palette.mediumGrey : ''
  }
}));
