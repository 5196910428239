import { SymbolMetadata } from '@/api/userApi';
import { convertToFractional } from '@/helpers/decimalHelper';

const numberFormatter = new Intl.NumberFormat('en-US', {
  useGrouping: true
});

const priceNoGroupingFormatter = new Intl.NumberFormat('en-US', {
  useGrouping: false,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const priceFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: true
});

const priceFormattersByDigits = [];

export const formatContractPrice = (value?: number, contract?: SymbolMetadata): string => {
  if (isNaN(value)) return '';
  if (contract.fractionalPrice) {
    return convertToFractional(value, contract.priceScale, contract.minMove, contract.minMove2);
  }
  const digits = contract?.decimalPlaces;

  if(digits < 0 || digits > 12) {
    console.warn('Invalid decimal places for contract', contract, digits);
  }

  if (digits !== null && digits !== undefined) {
    if (!priceFormattersByDigits[digits]) {
      priceFormattersByDigits[digits] = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
        useGrouping: true
      });
    }
    return priceFormattersByDigits[digits].format(value);
  }
  return priceFormatter.format(value);
};

export const getPriceDigitFormatter = (digits: number) => {
  if (!priceFormattersByDigits[digits]) {
    priceFormattersByDigits[digits] = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
      useGrouping: true
    });
  }
  return priceFormattersByDigits[digits];
};

export const formatPrice = (value?: number, digits?: number): string => {
  if (isNaN(value)) return '';

  if (digits !== null && digits !== undefined) {
    if (!priceFormattersByDigits[digits]) {
      priceFormattersByDigits[digits] = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
        useGrouping: true
      });
    }
    return priceFormattersByDigits[digits].format(value);
  }
  return priceFormatter.format(value);
};

export const formatNumber = (value?: number): string => {
  if (isNaN(value)) return '';
  return numberFormatter.format(value);
};

export const formatPriceNoGrouping = (value?: number): string => {
  if (isNaN(value)) return '';
  return priceNoGroupingFormatter.format(value);
};
