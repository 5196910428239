import { OrderStatus, OrderType, SymbolMetadata, TradingAccountStatus } from '@/api/userApi';
import { ButtonType } from '@/components/topstep/button';
import { OrderPromptType, useOrders } from '@/contexts/OrdersContext';
import { useTradingAccount } from '@/contexts/TradingAccountContext';
import { logException } from '@/helpers/exceptionHelper';
import React, { useCallback, useMemo, useState } from 'react';
import styles from './commonOrderOptions.module.scss';
import { Box } from '@mui/system';
import Modal, { ConfirmModal, ModalAction } from '@/components/topstep/modal';
import { useModal } from '@/contexts/ModalContext';
import { useSettings } from '@/contexts/SettingsContext';
import Heading from '@/components/topstep/heading';
import { violationTypeMap } from '@/data/enumTypeMaps';
import { DebouncedButton, StyledButton } from '@/components/styledComponents';
import { Theme, SxProps } from '@mui/material';
import QuantityButtons from './quantityButtons';
import { useDeviceContext } from '@/contexts/DeviceContext';
import KeyboardListener from './keyboardListener';
import { HotkeyActions } from './hotkeySettings';
import { toast } from 'react-toastify';

interface ContractSelectorProps {
  contract: SymbolMetadata;
  size: number;
  setSize: (size: number) => void;
  disableBuySell?: boolean;
  onBuy: () => void;
  onSell: () => void;
  onAction?: () => void;
  priceText?: string;
  hideBuySellButton?: boolean;
  hideClosePosition?: boolean;
  hideReversePosition?: boolean;
  hideCancelOrders?: boolean;
  hideJoinButton?: boolean;
  hideFlattenAll?: boolean;
  hideCancelAll?: boolean;
  hotkeysEnabled?: boolean;
}

const HalfButtonStyle: SxProps<Theme> = { flex: '1 1', margin: 0.3, padding: 0.4, flexBasis: 'max-content' };

const CommonOrderOptions: React.FC<ContractSelectorProps> = ({
  size,
  setSize,
  disableBuySell,
  onBuy,
  onSell,
  priceText,
  hideBuySellButton,
  hideClosePosition,
  hideReversePosition,
  hideCancelOrders,
  hideJoinButton,
  hideFlattenAll,
  hideCancelAll,
  contract,
  onAction,
  hotkeysEnabled
}): JSX.Element => {
  const { activeTradingAccount, getAccountTemplateData, tradingRules } = useTradingAccount();
  const { activePositions, closeAllPositions, cancelAll, cancelOrder, reversePosition, orders, closePosition, placeOrderWithSymbol } = useOrders();
  const [closePositionButtonOff, setClosePositionButtonOff] = useState(false);
  const [reversePositionButtonOff, setReversePositionButtonOff] = useState(false);
  const { showConfirmations: confirmation, customSettings } = useSettings();
  const { hideModal, showModal } = useModal();
  const { isMobile } = useDeviceContext();

  const activeOrders = useMemo(() => {
    return orders.filter((x) => x.status == OrderStatus.Open && x.symbolId == contract.symbol);
  }, [orders, contract]);

  const activePosition = useMemo(() => {
    return activePositions.find((x) => x.symbolId === contract.symbol && x.positionSize != 0);
  }, [activePositions, contract]);

  const canTrade = useMemo(() => {
    return activeTradingAccount.isFollower !== true && (activeTradingAccount?.status === TradingAccountStatus.Active || activeTradingAccount?.status == TradingAccountStatus.Ineligible);
  }, [activeTradingAccount.isFollower, activeTradingAccount?.status]);

  const canTradePosition = useMemo(() => {
    return canTrade && !!activePosition;
  }, [canTrade, activePosition]);

  const canCancelOrders = useMemo(() => {
    return canTrade && activeOrders.length > 0;
  }, [canTrade, activeOrders]);

  const hasOpenOrders = useMemo(() => {
    return orders.some((y) => y.status == OrderStatus.Open);
  }, [orders]);

  const hasOpenPositions = useMemo(() => {
    return activePositions.some((y) => y.positionSize != 0);
  }, [activePositions]);

  const cancelContractOrders = useCallback(async () => {
    onAction && onAction();

    for (const order of activeOrders) {
      try {
        await cancelOrder(order.id);
      } catch (e) {
        logException(e, 'Error cancelling order');
      }
    }
  }, [activeOrders]);

  const onReversePositionClick = useCallback(async () => {
    if (!canTrade) return;

    if (confirmation) {
      showModal(reversePositionModal(contract));
    } else {
      onAction && onAction();

      setReversePositionButtonOff(true);

      reversePosition(contract.symbol)
        .catch((e) => {
          logException(e, 'Error reversing position');
        })
        .finally(() => {
          setTimeout(() => {
            setReversePositionButtonOff(false);
          }, 2000);
        });
    }
  }, [canTrade, contract]);

  const cancelCurrentOrdersModal = useCallback(() => {
    return (
      <ConfirmModal
        header={`Cancel ${(contract?.friendlyName || ' ').substring(1)}${contract.maturityMonthYear} Orders`}
        onCancel={hideModal}
        onConfirm={() => {
          hideModal();
          onAction && onAction();
          cancelContractOrders();
        }}
      >
        Are you sure you wish to cancel all orders for {contract.fullName} ({(contract?.friendlyName || ' ').substring(1)}
        {contract.maturityMonthYear})?
      </ConfirmModal>
    );
  }, [contract, cancelContractOrders]);

  const onCancelOrdersClick = useCallback(() => {
    if (confirmation) {
      showModal(cancelCurrentOrdersModal());
    } else {
      onAction && onAction();
      cancelContractOrders();
    }
  }, [confirmation, cancelContractOrders, cancelCurrentOrdersModal]);

  const reversePositionModal = useCallback((contractVal: SymbolMetadata) => {
    return (
      <ConfirmModal
        header='Reverse Current Position'
        onCancel={hideModal}
        onConfirm={() => {
          hideModal();
          onAction && onAction();
          reversePosition(contractVal.symbol);
        }}
      >
        Are you sure you wish to reverse your position in {contractVal.fullName} ({(contractVal?.friendlyName || ' ').substring(1)}
        {contractVal.maturityMonthYear})?
      </ConfirmModal>
    );
  }, []);

  const cancelAllModal = useCallback(() => {
    return (
      <ConfirmModal
        header='Cancel All Orders'
        onCancel={hideModal}
        onConfirm={() => {
          hideModal();
          onAction && onAction();
          cancelAll();
        }}
      >
        Are you sure you wish to close all orders for all contracts?
      </ConfirmModal>
    );
  }, []);

  const onClickCancelAll = useCallback(() => {
    if (confirmation) {
      showModal(cancelAllModal());
    } else {
      onAction && onAction();
      cancelAll();
    }
  }, [confirmation]);

  const onClickFlattenAll = useCallback(async () => {
    if (confirmation) {
      showModal(flattenAllModal());
    } else {
      onAction && onAction();
      await closeAllPositions();
    }
  }, [confirmation]);

  const closeCurrentPositionModal = useCallback(() => {
    return (
      <ConfirmModal
        header={`Close ${(contract?.friendlyName || ' ').substring(1)}${contract.maturityMonthYear} Position`}
        onCancel={hideModal}
        onConfirm={() => {
          hideModal();
          onAction && onAction();
          closePosition(activePosition.symbolId);
        }}
      >
        Are you sure you wish to close your position in {contract.fullName} ({(contract?.friendlyName || ' ').substring(1)}
        {contract.maturityMonthYear})?
      </ConfirmModal>
    );
  }, [activePosition, contract, onAction]);

  const onClosePositonClick = useCallback(async () => {
    if (confirmation) {
      showModal(closeCurrentPositionModal());
    } else {
      if (!activePosition) return;
      onAction && onAction();
      setClosePositionButtonOff(true);
      closePosition(activePosition?.symbolId)
        .catch((e) => {
          logException(e, 'Error closing position');
        })
        .finally(() => {
          setTimeout(() => {
            setClosePositionButtonOff(false);
          }, 2000);
        });
    }
  }, [confirmation, activePosition, closeCurrentPositionModal, onAction]);

  const flattenAllModal = useCallback(() => {
    return (
      <ConfirmModal
        header='Flatten All Orders?'
        onCancel={hideModal}
        onConfirm={() => {
          hideModal();
          onAction && onAction();
          closeAllPositions();
        }}
      >
        Are you sure you wish to flatten all orders?
      </ConfirmModal>
    );
  }, [onAction]);

  const disableMessage = useMemo(() => {
    const violationType = activeTradingAccount.activeViolation?.type;
    if (activeTradingAccount.isFollower) {
      return 'Trade Copier Follower Account - No Manual Trading Allowed';
    }

    if (!!violationType) {
      if (!!activeTradingAccount.activeViolation.ruleId) {
        const rule = tradingRules.find((y) => y.id === activeTradingAccount.activeViolation.ruleId);
        return `${rule.name} Violation - No Trading Allowed`;
      }
      return `${violationTypeMap[violationType]} - No Trading Allowed`;
    }
  }, [activeTradingAccount.isFollower, activeTradingAccount.activeViolation, canTrade, tradingRules]);

  const confirmJoinModal = useCallback(
    (side: OrderPromptType, onConfirm: () => void) => {
      const isSell = side == OrderPromptType.Sell;
      const actions: ModalAction[] = [
        {
          label: 'Cancel',
          props: { buttonType: ButtonType.outline, className: styles.modalButton },
          onClick: hideModal
        },
        {
          label: `Confirm ${isSell ? 'Ask' : 'Bid'}`,
          props: {
            buttonType: isSell ? ButtonType.red : ButtonType.green,
            className: styles.modalButton
          },
          onClick: () => {
            onConfirm();
          }
        }
      ];
      return (
        <Modal header={<Heading className={isSell ? styles.redHeader : styles.greenHeader}>Confirm Order</Heading>} actions={actions}>
          <div>
            I want to Join {side == OrderPromptType.Buy ? 'Bid' : 'Ask'} on{' '}
            <span className={styles.bold}>
              {contract.fullName} ({(contract?.friendlyName || ' ').substring(1)}
              {contract?.maturityMonthYear})
            </span>{' '}
            with {size} QTY
          </div>
        </Modal>
      );
    },
    [size, contract]
  );

  const joinOrder = useCallback(async (side: OrderPromptType) => {
    const placeOrderCallback = async () => {
      hideModal();
      onAction && onAction();
      await placeOrderWithSymbol({
        symbol: contract.symbol,
        orderType: side,
        amount: size,
        type: side == OrderPromptType.Buy ? OrderType.JoinBid : OrderType.JoinAsk
      });
    };

    if (confirmation) {
      showModal(confirmJoinModal(side, placeOrderCallback));
    } else {
      await placeOrderCallback();
    }
  }, [size, contract, confirmation]);

  const customFontSize = useMemo(() => {
    if (customSettings.domFontSize == 0) {
      return { fontSize: 10 };
    } else if (customSettings.domFontSize == 1) {
      return { fontSize: 12 };
    } else if (customSettings.domFontSize == 2) {
      return { fontSize: 14 };
    } else {
      return { fontSize: 12 };
    }
  }, [customSettings.domFontSize]);

  const rejectHotkeyFollower = useCallback(() => {
    toast.error(<span><p><b>Hotkey Rejected!</b><br/>No trading on Trade Copier Follower Account</p></span>);
  }, []);

  const bindings = useMemo(() => {
    return [
      { keys: customSettings.hotkeyBindings?.[HotkeyActions.JoinBid], onKeyboard: activeTradingAccount.isFollower ? rejectHotkeyFollower : () => joinOrder(OrderPromptType.Buy) },
      { keys: customSettings.hotkeyBindings?.[HotkeyActions.JoinAsk], onKeyboard: activeTradingAccount.isFollower ? rejectHotkeyFollower : () => joinOrder(OrderPromptType.Sell) },
      { keys: customSettings.hotkeyBindings?.[HotkeyActions.ReversePosition], onKeyboard: activeTradingAccount.isFollower ? rejectHotkeyFollower : onReversePositionClick },
      { keys: customSettings.hotkeyBindings?.[HotkeyActions.ClosePosition], onKeyboard: activeTradingAccount.isFollower ? rejectHotkeyFollower : onClosePositonClick },
      { keys: customSettings.hotkeyBindings?.[HotkeyActions.CancelAll], onKeyboard: activeTradingAccount.isFollower ? rejectHotkeyFollower : onClickCancelAll },
      { keys: customSettings.hotkeyBindings?.[HotkeyActions.FlattenAll], onKeyboard: activeTradingAccount.isFollower ? rejectHotkeyFollower : onClickFlattenAll }
    ];
  }, [customSettings.hotkeyBindings, onReversePositionClick, onClosePositonClick, onClickCancelAll, onClickFlattenAll, joinOrder, activeTradingAccount.isFollower]);

  return (
    <Box className={!isMobile ? styles.mainBoxNotMobile : styles.mainBoxMobile} style={{ fontSize: customFontSize.fontSize }}>
      {!canTrade && (hasOpenPositions || hasOpenOrders) && !activeTradingAccount.isFollower && (
        <div className={styles.followerAlert}>
          <div className={styles.followerInner}>
            <div className={styles.followerInnerText}>
              <DebouncedButton
                color='success'
                onClick={() => {
                  closeAllPositions();
                  cancelAll();
                }}
                style={{ marginTop: '1em' }}
              >
                Flatten & Cancel All
              </DebouncedButton>
            </div>
          </div>
        </div>
      )}
      {disableMessage && <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 5 }}>{disableMessage}</div>}
      <QuantityButtons currentSize={size} setSize={setSize} disabled={!canTrade} />
      {!hideBuySellButton && (
      <Box className={!isMobile ? styles.buttonBoxNotMobile : styles.buttonBoxMobile} sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        <DebouncedButton size='large' disabled={!canTrade || disableBuySell} sx={HalfButtonStyle} onClick={onBuy} color='success'>
          Buy +{size} {priceText ?? 'MARKET'}
        </DebouncedButton>
        <DebouncedButton size='large' disabled={!canTrade || disableBuySell} sx={HalfButtonStyle} onClick={onSell} color='error'>
          Sell -{size} {priceText ?? 'MARKET'}
        </DebouncedButton>
      </Box>
      )}
      {!hideJoinButton && (
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          <DebouncedButton disabled={!canTrade} size='small' sx={HalfButtonStyle} color='neutral' onClick={() => joinOrder(OrderPromptType.Buy)}>
            Join Bid
          </DebouncedButton>
          <DebouncedButton disabled={!canTrade} size='small' sx={HalfButtonStyle} color='neutral' onClick={() => joinOrder(OrderPromptType.Sell)}>
            Join Ask
          </DebouncedButton>
        </Box>
      )}
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'stretch',
          justifyItems: 'stretch',
          alignContent: 'stretch',
          alignItems: 'stretch',
          flexWrap: 'wrap'
        }}
      >
        {!hideClosePosition && (
          <DebouncedButton color='neutral' sx={HalfButtonStyle} disabled={!canTradePosition || closePositionButtonOff} onClick={onClosePositonClick}>
            Close Position
          </DebouncedButton>
        )}
        {!hideReversePosition && (
          <DebouncedButton color='neutral' sx={HalfButtonStyle} disabled={!canTradePosition || reversePositionButtonOff} onClick={onReversePositionClick}>
            Reverse Position
          </DebouncedButton>
        )}
        {!hideCancelOrders && (
          <DebouncedButton color='neutral' sx={HalfButtonStyle} disabled={!canCancelOrders} onClick={() => canCancelOrders && onCancelOrdersClick()}>
            Cancel Orders
          </DebouncedButton>
        )}
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'stretch',
          justifyItems: 'stretch',
          alignContent: 'stretch',
          alignItems: 'stretch',
          flexWrap: 'wrap'
        }}
      >
        {!hideFlattenAll && (
          <DebouncedButton sx={HalfButtonStyle} color='neutral' disabled={!canTrade} size='small' onClick={onClickFlattenAll}>
            Flatten All
          </DebouncedButton>
        )}
        {!hideCancelAll && (
          <DebouncedButton sx={HalfButtonStyle} color='neutral' disabled={!canTrade} size='small' onClick={onClickCancelAll}>
            Cancel All
          </DebouncedButton>
        )}
      </Box>

      <KeyboardListener enabled={hotkeysEnabled} bindings={bindings} />
    </Box>
  );
};

export default React.memo(CommonOrderOptions);
